import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import arrowIcon from '@/assets/arrow.svg'
import BottomSheet from '@/components/bottom-sheet/BottomSheet.jsx'

const parse = markdown => {
  const regex = /\[([^\]]+)\]\(([^)]+)\)/
  const match = markdown.match(regex)

  if (match) {
    return {
      title: match[1],
      url: match[2]
    }
  }

  return null
}

export function TopicsLinksBottomSheet({ levelIndex, topics, handleClose }) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  let topicsArray = topics.map(item => parse(item))
  return (
    <BottomSheet title={t('levelNTopics', { levelIndex: levelIndex + 1 })} onClose={handleClose} wrapContent>
      <div className="mt-1 mx-3 bg-cl-dark1 rounded-4">
        {topicsArray.map((item, index) => (
          <div key={index} className={index === topicsArray.length - 1 ? '' : 'border-bottom'}>
            <div
              className="d-flex justify-content-between py-3 px-3"
              onClick={() => {
                console.log('open markdown')
                navigate(`/markdown/${encodeURIComponent(item.url)}`)
              }}
            >
              <div className="d-flex flex-row align-items-center">
                <div>{item.title}</div>
              </div>
              <img src={arrowIcon} alt="" width={24} height={24} />
            </div>
          </div>
        ))}
      </div>
      <div style={{ height: 65 }}></div>
    </BottomSheet>
  )
}
