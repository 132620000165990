import starEmpty from '@/assets/star_empty_40.svg'
import starFull from '@/assets/star_full_40.svg'

const STARS = 3

function LevelStars({ earnedStars }) {
  return (
    <div className="d-flex mb-1 justify-content-center" style={{ gap: 6 }}>
      {[...Array(STARS)].map((_, index) => (
        <img
          key={index}
          src={index < earnedStars ? starFull : starEmpty}
          width={20}
          height={20}
          alt="star"
          style={{
            marginTop: index === 1 ? 0 : 4
          }}
        />
      ))}
    </div>
  )
}

export default LevelStars
