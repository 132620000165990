import './PlanetStats.css'

import cn from 'clsx'
import { useTranslation } from 'react-i18next'

import RoundedProgressBar from '@/components/drag-slider/planet-stats/RoundedProgressBar.jsx'

function PlanetStats({ level, maxLevel, balance, maxBalance, currency = 'UWT' }) {
  const { t } = useTranslation()

  return (
    <div className="d-flex flex-column align-bottom p-3 gap-2">
      <div className="d-flex justify-content-between">
        <span className="planet-stats-text">{t('progress')}:</span>
        <span className="planet-stats-text accent">
          {level}/{maxLevel}
        </span>
      </div>
      <RoundedProgressBar progress={level} total={maxLevel} colors={['bg-white', 'bg-white-20']} />
      <div className="d-flex justify-content-between">
        <span className="planet-stats-text">{t('balance')}:</span>
        <div className="d-flex align-top">
          <span className={cn('planet-stats-text', { positive: balance > 0, negative: balance < 0 })}>
            {balance}&nbsp;
          </span>
          <span className="planet-stats-text warning">
            / {maxBalance} {currency}
          </span>
        </div>
      </div>
      <RoundedProgressBar progress={balance} total={maxBalance} colors={['bg-success', 'bg-warning']} />
    </div>
  )
}

export default PlanetStats
