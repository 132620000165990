import cn from 'clsx'
import { useSelector } from 'react-redux'

import home from '@/assets/home.svg'
import homeActive from '@/assets/home-active.svg'
import leaders from '@/assets/leaderboard.svg'
import leadersActive from '@/assets/leaderboard-active.svg'
import tasks from '@/assets/unordered.svg'
import tasksActive from '@/assets/unordered-active.svg'
import userIcon from '@/assets/user.svg'
import userActive from '@/assets/user-active.svg'
import { FooterItem } from '@/components/footer/FooterItem'
import HeaderFrame, { mainBottomPath } from '@/components/frame-bars/HeaderFrame'
import { selectUser } from '@/features/user/usersSlice'
import { useVisibilityAnimation } from '@/hooks/useVisibilityAnimation.js'

export function Footer({ visible }) {
  const user = useSelector(selectUser)

  const { shouldRender, animationClass } = useVisibilityAnimation(visible)

  if (!shouldRender) {
    return null
  }

  return (
    <footer className={cn('footer-navbar', animationClass)}>
      <div className="position-relative items-center">
        <HeaderFrame curvePath={mainBottomPath} />
        <div className="position-absolute d-flex bottom-0 py-2 w-100 gap-3">
          <div
            className="mx-5 px-4 w-100 bg-black d-flex flex-row align-items-center justify-content-between"
            style={{ borderRadius: 24, height: 56 }}
          >
            <FooterItem to="/" icon={home} activeIcon={homeActive} />
            <FooterItem to="/tasks" icon={tasks} activeIcon={tasksActive} showBadge={!user?.required_tasks_finished} />
            <FooterItem to="/rank" icon={leaders} activeIcon={leadersActive} />
            <FooterItem to="/user" icon={userIcon} activeIcon={userActive} />
          </div>
        </div>
        <div className="position-absolute safe-area-background"></div>
      </div>
    </footer>
  )
}
