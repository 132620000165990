import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import TaskRewardAlert from '@/components/alert/TaskRewardAlert.jsx'
import InstallWalletTask from '@/features/tasks/InstallWalletTask'
import Task from '@/features/tasks/Task'
import { fetchTasks, selectList, TaskGroups, Tasks } from '@/features/tasks/tasksSlice'

function TaskList() {
  const list = useSelector(selectList)
  const tasks = Object.values(list)
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const [rewardAlertInfo, setRewardAlertInfo] = useState(null)

  const deprecatedTasksEnglish = ['join-community-ru', 'follow-community-x-ru']
  const deprecatedTasksRussian = []

  const deprecatedTasks = i18n.language === 'ru' ? deprecatedTasksRussian : deprecatedTasksEnglish

  const filteredTasks = tasks.filter(task => task && !deprecatedTasks.includes(task.id))

  const groupedTasks = {
    becomeOneOfUs: filteredTasks.filter(task => TaskGroups.becomeOneOfUs.includes(task.id)),
    showThatYouCare: filteredTasks.filter(task => TaskGroups.showThatYouCare.includes(task.id)),
    forTrueSupporters: filteredTasks.filter(task => TaskGroups.forTrueSupporters.includes(task.id)),
    specialBond: filteredTasks.filter(task => TaskGroups.specialBond.includes(task.id))
  }

  useEffect(() => {
    dispatch(fetchTasks())
  }, [dispatch])

  let installWalletTask = tasks.find(task => task.id === Tasks.installWallet)

  const handleTaskComplete = ({ points }) => {
    dispatch(
      setRewardAlertInfo({
        amount: points
      })
    )
  }

  return (
    <div className="stars-container d-flex flex-column overflow-hidden mb-3 gap-3 mb-1">
      <div className="px-3">
        <div className="text-center py-3">
          <div className="h4">{t('shareNEarn')} 📣</div>
          <div className="base-font fs-6 text-white-50">{t('taskDescription')}</div>
        </div>

        {installWalletTask && <InstallWalletTask task={installWalletTask} />}

        {tasks.length > 0 && (
          <div style={{ paddingBottom: '20px' }}>
            {Object.entries(groupedTasks).map(([group, groupTasks]) => (
              <div key={group} style={{ marginBottom: '20px' }}>
                <div className="fw-bold mt-4">{t(group)}</div>
                <div className="rounded-4 bg-cl-dark mt-2">
                  {groupTasks.map((task, index) => (
                    <div key={task.id} className={index < groupTasks.length - 1 ? 'border-bottom' : ''}>
                      <Task task={task} onTaskComplete={handleTaskComplete} />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {rewardAlertInfo && (
        <TaskRewardAlert
          rewardAmount={rewardAlertInfo.amount}
          onClose={() => {
            setRewardAlertInfo(null)
          }}
        />
      )}
    </div>
  )
}

export default TaskList
