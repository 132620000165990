export function image(id, low = false) {
  const resolution = low ? '_low' : ''
  return `/planet_${id}${resolution}.png`
}

export function placeholder(index) {
  const names = ['baselion', 'satoshira', 'etheria']
  return image(names[index ?? 0], true)
}

export function currentLevel(planet) {
  if (planet?.quizzes === undefined) return 0

  for (let i = 0; i < planet.quizzes.length; i++) {
    const quiz = planet.quizzes[i]
    if (quiz.stars === 0) {
      return i
    }
  }
}

export function completedCount(planet) {
  return planet.quizzes.reduce((acc, q) => acc + (q.stars > 0 ? 1 : 0), 0)
}

export function earned(planet) {
  if (planet?.quizzes === undefined) return 0
  return planet.quizzes.reduce((total, quiz) => {
    return total + quiz.earned_points
  }, 0)
}

export function totalBalance(planet) {
  if (planet?.quizzes === undefined) return 0
  return planet.quizzes.reduce((total, quiz) => total + quiz.correct_points * quiz.questions, 0)
}

export function finished(planet) {
  if (planet?.quizzes === undefined) return false

  return completedCount(planet) === planet.quizzes.length
}
