import * as Sentry from '@sentry/react'

const IS_DEV = import.meta.env.DEV

const initialize = () => {
  if (IS_DEV) {
    return
  }

  Sentry.init({
    dsn: 'https://27ac79dc959ba519c202a8c2257f4b09@o4508453531680768.ingest.us.sentry.io/4508453549768704',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/dev-be\.unstoppable\.money\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const setUserError = data => {
  if (IS_DEV) {
    return
  }
  Sentry.setUser(data)
  console.log('Sentry user set:', data.id, data.username)
}

const setUser = (id, username) => {
  if (IS_DEV) {
    return
  }
  Sentry.setUser({
    id: id,
    username: username,
    tgVersion: window.Telegram.WebApp.version
  })
  console.log('Sentry user set:', id, username)
}

const handleException = error => {
  if (IS_DEV) {
    return
  }
  Sentry.captureException(error)
  console.error('Sentry exception captured:', error)
}

export const SentryAPI = {
  initialize,
  setUser,
  setUserError,
  handleException
}
