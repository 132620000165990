import { getQuizPlanets } from '@/api/api'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  list: [],
  listStatus: 'idle' // fetching/fetched/failed
}

export const planetSlice = createSlice({
  name: 'planets',
  initialState,
  reducers: {
    setList: (state, action) => {
      state.list = action.payload
      state.listStatus = 'fetched'
    },
    setListStatus: (state, action) => {
      state.listStatus = action.payload
    },
    updateQuiz: (state, action) => {
      const { quiz: updatedQuiz, planetId, level } = action.payload
      const planetIndex = state.list.findIndex(item => item.id === planetId)

      if (planetIndex === -1) return

      const quiz = state.list[planetIndex].quizzes[level]
      if (!quiz) {
        return
      }

      state.list[planetIndex].quizzes[level] = {
        ...quiz,
        ...updatedQuiz
      }
    }
  },

  selectors: {
    selectList: state => state.list,
    selectListStatus: state => state.listStatus,
    selectQuiz: (state, planetId, level) => state.list.find(item => item.id === planetId)?.quizzes[level]
  }
})

export const { setList, setListStatus, updateQuiz } = planetSlice.actions

export const { selectList, selectListStatus, selectQuiz } = planetSlice.selectors

export const fetchList =
  (forced = true) =>
  async (dispatch, getState) => {
    const status = selectListStatus(getState())

    if (!forced && status === 'fetched') {
      return
    }

    if (status === 'fetching') {
      return
    }

    dispatch(setListStatus('fetching'))

    getQuizPlanets()
      .then(planets => {
        dispatch(setList(planets.filter(i => i !== null).sort((a, b) => a.order - b.order)))
      })
      .catch(err => {
        console.log(err)
        dispatch(setListStatus('failed'))
      })
  }
