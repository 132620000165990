import cn from 'clsx'

export function QuizAnswers({ questionId, options, answers, correctAnswers, onSelect }) {
  const State = {
    IDLE: 'idle',
    PENDING: 'pending',
    CORRECT: 'correct',
    INCORRECT: 'incorrect'
  }

  return (
    <div className="d-flex flex-column gap-2">
      {options
        .sort((a, b) => a.randomIndex - b.randomIndex) // Sort by randomIndex
        .map((item, index) => {
          let state

          if (answers) {
            if (correctAnswers) {
              state = correctAnswers.includes(item.index)
                ? State.CORRECT
                : answers.includes(item.index)
                  ? State.INCORRECT
                  : State.IDLE
            } else {
              state = answers.includes(item.index) ? State.PENDING : State.IDLE
            }
          } else {
            state = State.IDLE
          }

          let bgClass

          switch (state) {
            case State.IDLE:
              bgClass = 'bg-white'
              break
            case State.PENDING:
              bgClass = 'bg-yellow-blink'
              break
            case State.CORRECT:
              bgClass = 'bg-green'
              break
            case State.INCORRECT:
              bgClass = 'bg-red'
          }

          return (
            <div
              key={questionId + index}
              className={cn(`btn-answer d-flex flex-row align-items-center justify-content-center py-3`, bgClass, {
                'btn-answer-active': !answers
              })}
              onClick={() => {
                if (!answers) {
                  onSelect(item.index)
                }
              }}
            >
              <div className={`base-font fw-bold fs-6 mx-4 text-black flex-grow-1`}>{item.answer}</div>
            </div>
          )
        })}
    </div>
  )
}

export default QuizAnswers
