import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import copyIcon from '@/assets/copy-icon.svg'
import globeIcon from '@/assets/globe-icon.svg'
import successIcon from '@/assets/success.svg'
import supportIcon from '@/assets/support-icon.svg'
import userIcon from '@/assets/user-active.svg'
import Loader from '@/components/loader/Loader'
import { SettingItem } from '@/features/settings/SettingItem.jsx'
import UserListItem from '@/features/user/UserListItem'
import { fetchFriends, selectFriends, selectFriendsStatus, selectUser } from '@/features/user/usersSlice'
import { botLink, inviteFriendsUrl } from '@/helpers/helper.js'

function UserProfile() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector(selectUser)
  const friends = useSelector(selectFriends)
  const friendsStatus = useSelector(selectFriendsStatus)
  const { t } = useTranslation()

  const shareText = t('joinText')
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    dispatch(fetchFriends())
  }, [dispatch])

  const loadingStatuses = ['idle', 'fetching']
  const loading = friends.length === 0 && loadingStatuses.includes(friendsStatus)

  if (loading) {
    return <Loader />
  }

  const onClickInvite = () => {
    window.Telegram.WebApp.openTelegramLink(inviteFriendsUrl(user?.referral_code, shareText))
  }

  const onCopyClick = () => {
    navigator.clipboard
      .writeText(botLink(user?.referral_code))
      .then(() => {
        setCopied(true)
        setTimeout(() => {
          setCopied(false)
        }, 2000)
      })
      .catch(err => {
        console.error('Failed to copy: ', err)
      })
  }

  const icon = copied ? successIcon : copyIcon
  let collected = 0
  if (friends.length !== 0) {
    collected = friends
      .map(it => {
        return Math.max(0, it.earned_points_for_referral + it.quiz_points)
      })
      .reduce((total, points) => total + points)
    collected = (collected / 10) | 0
  }

  return (
    <div className="stars-container">
      <div className="pt-3 z-1">
        <div className="bg-cl-dark1 rounded-24px p-3 mx-3">
          <h4 className="text-warning">{collected | 0} UWT</h4>
          <div className="base-font text-white-50">{t('earnRewards')}</div>
          <div className="d-flex mt-3">
            <button className="btn-action flex-fill text-black me-2" onClick={onClickInvite}>
              {t('invite')}
            </button>
            <button className="btn-action-circle rounded-circle flex-shrink-0" onClick={onCopyClick}>
              <img src={icon} alt="" height={24} width={24} />
            </button>
          </div>
        </div>
      </div>

      <div className="mx-3 mt-3 bg-cl-dark1 rounded-24px">
        <SettingItem title={t('profile')} icon={userIcon} onClick={() => navigate(`/profile/${user.id}`)} />
        <div className="border-bottom" />
        <SettingItem title={t('settings')} icon={supportIcon} onClick={() => navigate('/settings')} />
        <div className="border-bottom" />
        <SettingItem title={t('aboutUWT')} icon={globeIcon} onClick={() => navigate('/claim')} />
      </div>

      {friends.length > 0 && (
        <div>
          <div className="d-flex flex-column p-3 gap-3">
            <div className="scrollable-content mt-2">
              <div className="fw-semibold">
                {t('frens')} {friends.length}
              </div>
              <div className="mt-2 card rounded-24px bg-cl-dark z-0">
                {friends.map((item, i) => {
                  const earned = Math.max(0, ((item.earned_points + item.quiz_points) / 10) | 0)
                  const earnedPrefix = earned === 0 ? '' : '+'
                  return (
                    <div
                      key={item.id}
                      className={i === friends.length - 1 ? '' : 'border-bottom'}
                      onClick={() => navigate(`/profile/${item.id}`)}
                    >
                      <UserListItem
                        name={item.name}
                        avatar={item.avatar}
                        prefix={earnedPrefix}
                        value={earned}
                        showRankEmoji={false}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default UserProfile
