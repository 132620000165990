import Animation from '@/components/animation/Animation.jsx'

const QuizQuestionBoxMessage = ({ animation, text }) => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <Animation className="s28" name={animation} loop={false} />
      <div className="ms-2 fs-6 fw-bold">{text}</div>
    </div>
  )
}

export default QuizQuestionBoxMessage
