function UserListItem({ name, avatar, prefix, value, showRankEmoji = true, subtitle }) {
  if (!name) {
    return (
      <div className="placeholder-wave d-flex align-items-center p-3">
        <div className="placeholder rounded-circle s32 text-grey" />
        <div className="ps-3 d-flex justify-content-between align-items-center flex-fill">
          <div className="d-flex flex-column">
            <div className="placeholder rounded mb-0 fs-6 pch-7 text-grey">A</div>
            <div className="placeholder rounded fs-7 fs-8 pch-6 text-grey">A</div>
          </div>
          <div className="placeholder rounded fs-6 text-grey pch-4">A</div>
        </div>
      </div>
    )
  }

  const rankEmoji = showRankEmoji ? (value <= 3 ? ['🥇', '🥈', '🥉'][value - 1] : null) : null

  return (
    <div className="d-flex align-items-center p-3">
      {avatar ? (
        <img src={avatar} className="rounded-circle s32 flex-shrink-0" alt="user" />
      ) : (
        <div className="d-flex flex-row s32 justify-content-center align-items-center mb-0 rounded-circle bg-warning text-black flex-shrink-0">
          {name.charAt(0).toUpperCase()}
        </div>
      )}
      <div className="ps-3 d-flex align-items-center justify-content-between flex-fill">
        <div className="me-3">
          <p className="fs-6 text-white mb-0">{name}</p>
          <div className="base-font fs-8 text-white-50">{subtitle?.toLocaleString()} UWT</div>
        </div>
        {rankEmoji ? (
          <div className="fs-1">{rankEmoji}</div>
        ) : (
          <h6 className="mb-0 me-2">
            {prefix}
            {value?.toLocaleString()}
          </h6>
        )}
      </div>
    </div>
  )
}

export default UserListItem
