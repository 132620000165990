import './DragSlider.scss'

import next from '@/assets/next.png'
import previous from '@/assets/previous.png'

function ChangeButton({ direction, hide, disabled, onClick }) {
  const visibilityClass = hide ? 'opacity-0 z-n1' : 'opacity-100 z-1'
  return (
    <button
      className={`btn-change-index ${direction} ${visibilityClass}`}
      onClick={() => {
        onClick()
      }}
      disabled={disabled}
    >
      <img src={direction === 'left' ? previous : next} width={24} height={24} alt={'direction'} />
    </button>
  )
}

export default ChangeButton
