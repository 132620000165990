const QuizLoading = () => {
  return (
    <div className="px-3">
      <div className="bg-black-40 rounded-32px" style={{ height: 164 }} />

      <div className=" mt-3">
        <div className="d-flex flex-column gap-2">
          {[...Array(4).keys()].map((_, i) => (
            <div key={i} className="py-3 rounded-32px bg-white-40" style={{ height: 60 }} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default QuizLoading
