export const BACKGROUND_PLANET_ASPECT = 200 / 180

export function sizes(rect, insets) {
  const safe = insets ?? { top: 0, right: 0, bottom: 0, left: 0 }
  const planetSize = Math.max(
    Math.min((rect.width ?? 0) - safe.left - safe.right, (rect.height ?? 0) - safe.top - safe.bottom),
    50
  )
  return { planetSize, aspectSize: planetSize * BACKGROUND_PLANET_ASPECT }
}

export function inRect(e, windowSize, aspectSize) {
  return (
    Math.abs(windowSize.width / 2 - e.clientX) < aspectSize / 2 &&
    Math.abs(windowSize.height / 2 - e.clientY) < aspectSize / 2
  )
}

export function levelPlanetScale(size, height) {
  return height / size
}

export function arcAngle(radius, length) {
  const angleInRadians = length / radius
  return angleInRadians * (180 / Math.PI)
}

export function arcAngleByCount(radius, count) {
  const l = 2 * Math.PI * radius
  return arcAngle(radius, l / count)
}

export function arcAngleByDx(radius, dx) {
  const angle = Math.asin(dx / radius)
  return angle * (180 / Math.PI)
}

export function correctedAngle(width, radius, count) {
  // show 3 level badges with small oversize for 15px
  const dx = Math.min(radius, width / 2) - 15

  const recommendedAngle = arcAngleByDx(radius, dx)
  const maximumAngle = arcAngleByCount(radius, count)
  return Math.min(recommendedAngle, maximumAngle)
}
