import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const { user = {} } = window.Telegram.WebApp.initDataUnsafe

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: user.language_code || 'en',
    fallbackLng: 'en',
    resources: {
      en: {
        translation: {
          // :launcher
          learnAndEarn: 'Learn & Earn!\n\nBe Unstoppable! 🚀',

          // :footer
          home: 'Home',
          tasks: 'Tasks',
          leaders: 'Leaders',
          frens: 'Frens',
          claim: 'Claim',

          leaderboard: 'Leaderboard',
          reward: 'Reward',
          startYourJourney: 'Begin your space journey with a special launch bonus! 🚀',
          completed: 'View Results',
          answered: 'answered',
          earned: 'earned',
          holders: 'Holders',
          shareNEarn: 'Stack UWT tokens!',
          taskDescription:
            'Bring friends, spread the word, and score big! Download Unstoppable Wallet for a mega boost! 🚀',
          joinText:
            'Say “no” to losers — join the unstoppable! 🛸 Master crypto, claim your 400 UWT, and Be Unstoppable! ⭐️ Jump in now!',

          // :home
          blumBonus: 'Blum Bonus!',
          blumDescription: 'Special welcome gift for Blum users!',
          thanks: 'Thanks',
          balance: 'Balance',
          progress: 'Progress',
          // :tasks
          installWallet: 'Install Wallet',
          earnRewards: 'Level Up Together! 🚀 Get 10% Friend Points + 240 UWT Per Invite!',
          invite: 'Invite',
          copied: 'Copied',
          get: 'GET',

          // :claim
          claimDescription:
            'Tackle missions, smash quizzes, and stack those rewards. The more you know, the more UWT you’ll grab! 💫 Here’s what UWT unlocks:',
          accessPremium: 'Premium Unstoppable Wallet features',
          voting: 'Your vote on what gets built next',
          exclusiveCrew: 'Access to the exclusive crew of unstoppable people',
          airdrops: 'VIP airdrops from future projects',
          timeToLearn: 'No excuses—dive in, learn, and Be Unstoppable! 🛸',
          followOurNews: 'Stay locked into our channels for token launch updates—don’t miss a beat! 📡',
          noQuiz: '⚠️ Mission data not loaded!',

          //:planet
          levelNTopics: 'Level {{levelIndex}} Topics',
          levelTopics: 'Level Topics',
          startQuiz: 'Start',
          continueQuiz: 'Continue Quiz',
          retakeQuiz: 'Retake Quiz',
          tryAgainQuiz: 'Try Again',
          planetLocked: 'Planet Locked',
          planetLockedDescription: 'Complete all levels on planet {{previousPlanet}} to unlock planet {{thisPlanet}} .',
          // :quiz
          energy: '⛽',
          outOfEnergy: 'TANK EMPTY',
          level: 'Level',
          locked: 'Sector Locked 🔒',
          continue: 'Return to Base!',
          start: 'Launch',
          go: 'Start 🚀',
          theseTopic: 'Master these space sectors to gain vital knowledge and earn maximum mission rewards!',
          correctAnswer: 'Correct!',
          wrongAnswer: 'Incorrect!',
          explanation: 'Explanation',
          fillUpEnergy: 'Your ship needs more gas! Automatic refueling in progress... ⚡️',
          fuelIsLow: '⚠️ Low Gas Warning!',
          waitForEnergyToRestore: 'Refueling in progress... Full tank in 24 hours! 🛸',
          gas: '⛽GAS',
          gasDescription:
            "Taking quizzes uses your ship's gas. Don't worry - your tank gets small refills every 2 hours, with a full tank restored in 24 hours.",
          time: 'Time',
          sec: 'sec',
          next: 'Next',
          answer: 'Answer',
          congratulation: 'Mission Success! 🎯',
          excellent: 'Excellent!',
          awesome: 'Awesome!',
          good: 'Good!',
          needsWork: 'Needs Work!',
          levelCompleted: 'Level {{level}} - Completed',
          levelFailed: 'You need at least 6 correct answers to advance to the next level.',
          youEarned: 'You earned UWT',
          youPassedTest: 'You passed the test',
          goToHome: 'Return to Base',
          goToTasks: 'Go to Tasks',
          timeOut: 'Time is Out!',
          retry: 'Retry',
          tryAgain: 'Try Again',
          error: '⚠️ System Malfunction',
          quizError: 'Unexpected Error',
          quizInsufficientGas: 'Insufficient Gas!',
          quizInsufficientGasDescription:
            'Your tank gets small refills every 2 hours, with a full tank restored in 24 hours. \n\nKeep earning points by completing tasks!',

          // :settings
          settings: 'Settings',
          aboutUWT: 'About UWT',
          support: 'Tech Support',
          faq: 'FAQ',
          faqUrl: 'https://telegra.ph/FAQ--Be-Unstoppable-11-06',
          beUnstoppableChannel: 'Be Unstoppable Channel',
          beUnstoppableUrl: 'https://t.me/unstoppable_announcements',
          unstoppableWallet: 'Unstoppable Wallet',
          yourReferralLink: 'Your Referral Link',
          share: 'Share',
          language: 'Language',
          apply: 'Apply',

          //:profile
          profile: 'Profile',
          totalEarned: 'Total Earned',
          quizRewards: 'Quiz Rewards',
          earnedFromTasks: 'Earned From Tasks',
          frensInfo: '240 for each friend',
          referrals: 'Referrals',
          referralsInfo: '10% of the frens rewards',
          somethingWrong: 'Houston, we have a problem!',
          goBack: 'Return to Ship',
          correctAnswers: 'Correct Answers',
          wrongAnswers: 'Wrong Answers',

          //:tasks
          shareUrl: '/story_image.png',
          joinCommunity: 'Join to Unstoppable Space!',
          youHaveEarnedTokens: 'You have earned',
          successExclamation: 'Success!',
          becomeOneOfUs: 'Become One of Us',
          showThatYouCare: 'Show That You Care',
          forTrueSupporters: 'For True Supporters',
          specialBond: 'Special Bond'
        }
      },
      ru: {
        translation: {
          // :launcher
          learnAndEarn: 'Учись. Зарабатывай.\n\nBe Unstoppable! 🚀',

          // :footer
          home: 'Главная',
          tasks: 'Задания',
          leaders: 'Рейтинг',
          frens: 'Друзья',
          claim: 'Получить',
          leaderboard: 'Таблица лидеров',
          reward: 'Награда',
          startYourJourney: 'Начни космическое путешествие с приветственным бонусом! 🚀',
          completed: 'Статистика',
          answered: 'отвечено',
          earned: 'заработано',
          holders: 'Держатели',
          shareNEarn: 'Собирай токены UWT!',
          taskDescription:
            'Приводи друзей, распространяй информацию и зарабатывай по-крупному! Скачай Unstoppable Wallet для максимального бонуса! 🚀',
          joinText:
            'Скажи «нет» лузерам — присоединяйся к непобедимым! 🛸Прокачай крипто-знания, забирай свои 400 UWT и будь непобедим! ⭐️ Врывайся сейчас!',

          // :home
          blumBonus: 'Бонус от Blum!',
          blumDescription: 'Специальный подарок для пользователей Blum!',
          thanks: 'Спасибо',
          balance: 'Баланс',
          progress: 'Прогресс',

          // :tasks
          installWallet: 'Установи Кошелек',
          earnRewards: 'Растем Вместе! 🚀 Получай 10% от очков друга + 240 UWT за каждое приглашение!',
          invite: 'Пригласить',
          copied: 'Скопировано',
          get: 'ПОЛУЧИ',

          // :claim
          claimDescription:
            'Выполняй миссии, проходи квизы и собирай награды. Чем больше знаешь, тем больше UWT получишь! 💫 Вот что тебе откроет UWT:',
          accessPremium: 'Премиум-функции в Unstoppable Wallet',
          voting: 'Твой голос в решении, какие функции добавить',
          exclusiveCrew: 'Доступ к команде непобедимых',
          airdrops: 'Эксклюзивные аирдропы от будущих проектов',

          timeToLearn: 'Без оправданий — действуй, учись и будь непобедим! 🛸',
          followOurNews: 'Следи за нашими официальными каналами, чтобы не пропустить запуск токена — будь в теме! 📡',
          noQuiz: '⚠️ Данные миссии не загружены!',

          //:planet
          levelNTopics: 'Темы {{levelIndex}}-уровня',
          levelTopics: 'Темы уровня',
          startQuiz: 'Начать',
          continueQuiz: 'Продолжить тест',
          retakeQuiz: 'Пройти тест заново',
          tryAgainQuiz: 'Пройти тест заново',
          planetLocked: 'Планета заблокирована',
          planetLockedDescription:
            'Пройдите все уровни на планете {{previousPlanet}}, чтобы разблокировать планету {{thisPlanet}}.',

          // :quiz
          energy: '⛽',
          level: 'Уровень',
          locked: 'Сектор закрыт 🔒',
          continue: 'Вернуться на базу!️',
          start: 'Начать',
          go: 'Поехали! 🚀',
          theseTopic: 'Исследуй эти сектора, получи важные знания и максимум наград! 🌟',
          correctAnswer: 'Правильно',
          wrongAnswer: 'Неправильно',
          fillUpEnergy: 'Кораблю нужно топливо! Идёт автоматическая заправка... ⚡️',
          fuelIsLow: '⚠️ Мало топлива!',
          waitForEnergyToRestore: 'Идёт заправка... Полный бак через 24 часа! 🛸',
          gas: '⛽Топливо',
          gasDescription:
            'Прохождение тестов расходует топливо корабля. Не волнуйся – бак пополняется каждые 2 часа, а полная заправка происходит за 24 часа 🚀',
          time: 'Время',
          sec: 'сек',
          next: 'Далее',
          answer: 'Ответить',
          congratulation: 'Миссия выполнена! 🎯',
          excellent: 'Превосходно!️',
          awesome: 'Отлично!',
          good: 'Хорошо!',
          needsWork: 'Повтори материал!',
          youHaveEarned: 'Награда 💎:',
          levelCompleted: 'Уровень {{level}} - Пройден',
          levelFailed: 'Для перехода на следующий уровень необходимо минимум 6 правильных ответов.',
          youEarned: 'Вы заработали UWT',
          correct: 'Верно: {{amount}}',
          wrong: 'Неверно: {{amount}}',
          youPassedTest: 'Тест пройден',
          goToHome: 'Вернуться на базу',
          goToTasks: 'К заданиям',
          timeOut: 'Время вышло!',
          retry: 'Повторить',
          error: '⚠️ Сбой системы',
          tryAgain: 'Повторить снова',
          quizError: 'Непредвиденная ошибка',
          quizInsufficientGas: 'Недостаточно газа!',
          quizInsufficientGasDescription:
            'Ваш бак пополняется небольшими дозами каждые 2 часа, а полностью восстанавливается за 24 часа.\n\nПродолжайте зарабатывать очки, выполняя задания!',

          // :settings
          settings: 'Настройки',
          aboutUWT: 'О токене UWT',
          support: 'Тех.поддержка',
          faq: 'FAQ',
          faqUrl: 'https://telegra.ph/FAQ--Be-Unstoppable-11-06-2',
          beUnstoppableChannel: 'Наш Telegram Канал',
          beUnstoppableUrl: 'https://t.me/unstoppable_announcements_ru',
          unstoppableWallet: 'Unstoppable Wallet',
          yourReferralLink: 'Твоя реферальная ссылка',
          share: 'Поделиться',
          language: 'Язык',
          apply: 'Применить',

          //:profile
          profile: 'Профиль',
          totalEarned: 'Всего заработано',
          quizRewards: 'Награды за квизы',
          earnedFromTasks: 'Заработано на заданиях',
          frensInfo: '240 UWT за каждого друга',
          referrals: 'Рефералы',
          referralsInfo: '10% от наград рефералов',
          somethingWrong: 'Хьюстон, у нас проблема!',
          goBack: 'Вернуться на корабль',
          correctAnswers: 'Правильные ответы',
          wrongAnswers: 'Неправильные ответы',

          //:tasks
          shareUrl: '/story_image_ru.png',
          joinCommunity: 'Присоединяйся к Unstoppable Space!',
          youHaveEarnedTokens: 'Вы получили',
          successExclamation: 'Задание выполнено',
          becomeOneOfUs: 'Стань Одним из Нас',
          showThatYouCare: 'Покажи, Что Ты С Нами',
          forTrueSupporters: 'Для Настоящих',
          specialBond: 'Особая Связь'
        }
      }
    }
  })
