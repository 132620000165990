import './BottomSheet.css'

import { useEffect, useState } from 'react'

import closeIcon from '@/assets/close-popup-icon.svg'

const BottomSheet = ({ title, layoutZIndex = 2, onClose, wrapContent, children }) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setIsVisible(true)
  }, [])

  const handleClose = () => {
    setIsVisible(false)

    setTimeout(() => onClose(), 300)
  }

  return (
    <div
      className={`bottom-sheet-backdrop ${isVisible ? 'show' : ''}`}
      style={{
        zIndex: layoutZIndex
      }}
      onClick={handleClose}
    >
      <div
        className={`position-fixed rounded-top-5 bottom-0 start-0 vw-100 bg-dark transition-transform ${isVisible ? 'translate-y-0' : 'translate-y-100'}`}
        style={{
          [wrapContent ? 'maxHeight' : 'height']: '100vh',
          transform: isVisible ? 'translateY(0)' : 'translateY(100%)',
          transition: 'transform 0.3s ease-out',
          boxShadow: '0 -4px 6px -1px rgba(0, 0, 0, 0.1), 0 -2px 4px -1px rgba(0, 0, 0, 0.06)'
        }}
        onClick={e => e.stopPropagation()}
      >
        <div className="d-flex justify-content-center align-items-center position-relative" style={{ height: 72 }}>
          <div className="fs-5 fw-bold text-white">{title}</div>
          <img
            className=""
            onClick={handleClose}
            src={closeIcon}
            style={{ width: '24px', height: '24px', position: 'absolute', right: '24px' }}
          />
        </div>
        {children}
      </div>
    </div>
  )
}

export default BottomSheet
