import { useEffect } from 'react'
import { useRouteError } from 'react-router-dom'

import { SentryAPI } from '@/helpers/sentry'

function RouteError() {
  const error = useRouteError()

  useEffect(() => {
    SentryAPI.handleException(error)
  }, [error])

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  )
}

export default RouteError
