import { useNavigate } from 'react-router-dom'

import UserListItem from '@/features/user/UserListItem'

export function RankList({ loading, list }) {
  const navigate = useNavigate()

  if (loading || !list) {
    const array = [...Array(8).keys()]
    return (
      <div className="d-flex flex-column rounded-4 bg-cl-dark">
        {array.map((_, i) => (
          <div key={i} className={i === array.length - 1 ? '' : 'border-bottom'}>
            <UserListItem />
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className="d-flex flex-column overflow-hidden rounded-4 bg-cl-dark">
      {list.map((user, i) => (
        <div
          key={i}
          className={i === list.length - 1 ? '' : 'border-bottom'}
          onClick={() => navigate(`/profile/${user.id}`)}
        >
          <UserListItem name={user.name} avatar={user.avatar} prefix="#" value={user.rank} subtitle={user.points} />
        </div>
      ))}
    </div>
  )
}

export default RankList
