import { io } from 'socket.io-client'

import { setCompleted } from '@/features/tasks/tasksSlice.js'
import { setHasWelcomeReward, setPoints } from '@/features/user/usersSlice.js'
import { store } from '@/store.js'

class Socket {
  constructor() {
    this.client = null
  }

  connect(token) {
    this.disconnect()

    this.client = io(import.meta.env.VITE_SOCKET_URL || '', {
      extraHeaders: {
        authorization: `Bearer ${token}`
      }
    })

    this.client.on('task-update', event => {
      this.onTaskUpdate(event)
    })

    this.client.on('points-update', event => {
      this.onPointsUpdate(event)
    })
  }

  disconnect() {
    if (this.client) {
      this.client.disconnect()
    }
  }

  emit(channel, data) {
    if (this.client) {
      return this.client.emit(channel, data)
    }
  }

  onTaskUpdate(event) {
    if (event.taskId && event.completed === true) {
      if (event.taskId.includes('-welcome-reward')) {
        store.dispatch(setHasWelcomeReward(false))
      } else {
        store.dispatch(setCompleted(event.taskId))
      }
    }
  }

  onPointsUpdate(event) {
    store.dispatch(setPoints(event))
  }
}

export const socket = new Socket()
