import exitIcon from '@/assets/exit_icon.svg'
import Animation from '@/components/animation/Animation.jsx'

const QuizMessage = ({ animation, title, text, bigButtonText, onBigButtonClick, onExitButtonClick }) => {
  return (
    <div>
      <div className="h-100 text-center align-content-center pt-3 px-5">
        <Animation className="w-100 s100" name={animation} loop={false} />
        <div className="mt-3 fs-2 fw-bold">{title}</div>
        <div className="mt-2 fs-6">{text}</div>
      </div>

      <div className="position-absolute bottom-0 w-100 p-3 mb-3 z-2 d-flex flex-row">
        {onExitButtonClick && (
          <button className="btn-action-circle rounded-circle flex-shrink-0 me-2" onClick={onExitButtonClick}>
            <img src={exitIcon} alt="" height={24} width={24} />
          </button>
        )}
        <button className="btn-action" onClick={onBigButtonClick}>
          {bigButtonText}
        </button>
      </div>
    </div>
  )
}

export default QuizMessage
