import { NavLink } from 'react-router-dom'

export const FooterItem = ({ to, icon, activeIcon, showBadge = false }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) => `${isActive ? 'nav-link nav-link-active' : 'nav-link nav-link-inactive'} px-2`}
    >
      {({ isActive }) => (
        <div className="d-flex flex-column align-items-center position-relative">
          <img className="navigation" src={isActive ? activeIcon : icon} width={24} height={24} />
          {showBadge && (
            <span
              className="position-absolute top-0 start-100 translate-middle p-1 bg-danger rounded-circle"
              style={{
                width: '8px',
                height: '8px'
              }}
            ></span>
          )}
        </div>
      )}
    </NavLink>
  )
}
