import './Levels.scss'

import cn from 'clsx'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { STATUS } from '@/components/drag-slider/DragSliderAnimationLine.js'
import LevelBadge from '@/features/planet/planet/LevelBadge.jsx'
import LevelBottomButtons from '@/features/planet/planet/LevelBottomContainer.jsx'
import LevelStars from '@/features/planet/planet/LevelStars.jsx'
import Orbit from '@/features/planet/planet/Orbit.jsx'
import { correctedAngle } from '@/helpers/uiHelper.js'
import useWindowSize from '@/hooks/useWindowSize.js'

function Levels({ className, planet, radius, levelIndex, setStatus, startClicked, onOpenTopics }) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const root = document.querySelector(':root')
  const sliderRef = useRef()
  const windowSize = useWindowSize()

  const currentIndex = () => parseFloat(getComputedStyle(root).getPropertyValue('--levelIndex'))

  const statuses = useMemo(() => {
    if (planet.quizzes.length === 0) return []

    let result = []
    let foundCurrent = false
    for (let i = 0; i < planet.quizzes.length; i++) {
      const quiz = planet.quizzes[i]
      let quizState = ''

      if (quiz.stars > 0) {
        quizState = 'completed'
      } else if (quiz.answers === quiz.questions && quiz.stars === 0) {
        quizState = 'not-passed'
        foundCurrent = true
      } else if (quiz.answers === 0) {
        quizState = foundCurrent ? 'disabled' : 'not-started'
      } else {
        quizState = 'current'
        foundCurrent = true
      }

      if (quizState === 'not-started' && !foundCurrent) {
        foundCurrent = true
      }

      result.push({ type: quizState, earnedStars: quiz.stars })
    }

    return result
  }, [planet])

  useEffect(() => {
    const index = currentIndex()
    const angle = correctedAngle(windowSize.width, radius, planet.quizzes.length)
    root.style.setProperty('--levelAngle', `${angle}deg`)

    /*set current level in center*/
    root.style.setProperty('--planetTranslateAngle', `-${index * angle}deg`)
  }, [planet, radius, windowSize])

  const clickStart = useCallback(() => {
    const steps = [STATUS.landingShip]
    const timer = setTimeout(() => {
      navigate(`/planet/${planet.id}/${levelIndex + 1}/quiz`)
    }, STATUS.landingShip.duration)
    setStatus(steps)
    startClicked()
    return () => clearTimeout(timer)
  }, [navigate, planet, levelIndex, setStatus])

  const buttonState = useMemo(() => {
    const status = statuses[levelIndex]?.type ?? 'disabled'
    switch (status) {
      case 'completed':
        return { title: t('completed'), disabled: false }
      case 'not-passed':
        return { title: t('tryAgainQuiz'), disabled: false }
      case 'not-started':
        return { title: t('startQuiz'), disabled: false }
      case 'current':
        return { title: t('continueQuiz'), disabled: false }
      case 'disabled':
        return { title: t('startQuiz'), disabled: true }
    }
    return {}
  }, [statuses, levelIndex, t])

  return (
    <div className={cn('level-slider', className)}>
      {/*level starts with LEVEL*/}
      <div className="level-description">
        <span className="h4 fw-bold mb-3">{t('level').toUpperCase()}</span>
        <LevelStars earnedStars={statuses[levelIndex]?.earnedStars} />
      </div>

      <LevelBottomButtons
        buttonTitle={buttonState.title}
        disabled={buttonState.disabled}
        onClickTopics={() => {
          onOpenTopics(planet.quizzes[levelIndex].links)
        }}
        onClick={clickStart}
      ></LevelBottomButtons>
      {/* scrolling part */}
      <div ref={sliderRef} className="level-orbit-container">
        {/*orbit line*/}
        <Orbit radius={radius} />

        {/*quiz levels slider*/}
        {planet.quizzes.map((src, i) => {
          let status = statuses[i]?.type ?? 'disabled'

          return (
            <div key={i} className={`level-item-${i}`}>
              <LevelBadge key={i} planetId={planet.id} level={src.order} status={status} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Levels
