import { authenticate, getProfile } from '@/api/api.js'
import { socket } from '@/api/socket'
import { logoutSuccess, setUserStatus, syncAuthenticatedUser } from '@/features/user/usersSlice'
import { SentryAPI } from '@/helpers/sentry'
import { store } from '@/store'

export const appLoader = async () => {
  if (import.meta.env.VITE_JWT) {
    localStorage.setItem('jwt', import.meta.env.VITE_JWT)
  }

  const token = localStorage.getItem('jwt')

  if (token) {
    try {
      store.dispatch(setUserStatus('fetching'))
      const user = await getProfile()
      store.dispatch(syncAuthenticatedUser(user))
      socket.connect(token)
      return
    } catch (e) {
      localStorage.removeItem('jwt')
      store.dispatch(setUserStatus('failed'))
    }
  }

  try {
    const url = new URL(window.location)
    const startParam = url.searchParams.get('tgWebAppStartParam')

    let params = {}
    switch (startParam) {
      case 'blum':
        params = { partnerId: startParam }
        break
      default:
        params = { referralCode: startParam }
    }

    store.dispatch(setUserStatus('fetching'))
    const { jwt, user } = await authenticate(
      window.Telegram.WebApp.initData,
      params.referralCode,
      params.partnerId,
      null
    )
    store.dispatch(syncAuthenticatedUser(user))
    localStorage.setItem('jwt', jwt)
    socket.connect(jwt)
  } catch (e) {
    localStorage.removeItem('jwt')
    store.dispatch(logoutSuccess())
    socket.disconnect()
    SentryAPI.handleException(e)
    const data = window.Telegram.WebApp.initDataUnsafe.user
    SentryAPI.setUserError({
      error: 'User not found',
      id: data?.id ?? 'unknown',
      username: data?.username ?? ''
    })
  }
}
