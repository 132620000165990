import './Quiz.css'

import cn from 'clsx'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import starEmpty from '@/assets/star_empty_40.svg'
import starFull from '@/assets/star_full_40.svg'
import Animation from '@/components/animation/Animation.jsx'
import { BackIcon } from '@/components/Icon.jsx'
import QuizResultBar from '@/features/planet/quiz/QuizResultBar.jsx'

function QuizResult({ quiz, onFinish, onTryAgain }) {
  const [visibleStars, setVisibleStars] = useState(0)
  const [answersProgress, setAnswersProgress] = useState(false)
  const [earnedProgress, setEarnedProgress] = useState(false)
  const [answersResult, setAnswersResult] = useState(false)
  const [earnedResult, setEarnedResult] = useState(false)
  const [showConfetti, setShowConfetti] = useState(false)

  const { t } = useTranslation()

  const stars = 3
  const earnedStars = quiz.stars

  useEffect(() => {
    let timer

    setTimeout(() => {
      setAnswersProgress(true)
    }, 500)

    setTimeout(() => {
      setEarnedProgress(true)
      setAnswersResult(true)
    }, 1000)

    setTimeout(() => {
      setEarnedResult(true)
    }, 1500)

    setTimeout(() => {
      timer = setInterval(() => {
        setVisibleStars(prev => {
          const next = prev + 1

          if (next >= earnedStars) {
            clearInterval(timer)

            if (earnedStars > 0) {
              setTimeout(() => {
                setShowConfetti(true)
              }, 500)
            }
          }

          return next
        })
      }, 500)
    }, 1700)

    return () => {
      clearInterval(timer)
    }
  }, [])

  let animationName
  let animationText

  switch (earnedStars) {
    case 3:
      animationName = 'Boomstick'
      animationText = 'excellent'
      break
    case 2:
      animationName = 'Awesome'
      animationText = 'awesome'
      break
    case 1:
      animationName = 'ThumbsUp'
      animationText = 'good'
      break
    default:
      animationName = 'Book'
      animationText = 'needsWork'
      break
  }

  return (
    <div>
      <div className="d-flex flex-column h-100">
        <div className="d-flex align-items-center flex-grow-1">
          <div className="d-flex flex-column align-items-center w-100">
            <Animation className="s128" name={animationName} loop={false} />
            <div className="mt-3 fs-4 fw-bold">{t(animationText)}</div>

            {earnedStars === 0 ? (
              <div className="base-font text-white text-center mb-3 mt-2 mx-5">{t('levelFailed')}</div>
            ) : (
              <div>
                <div className="base-font text-success text-center mb-3 mt-2">
                  {t('levelCompleted', { level: quiz.order })}
                </div>

                <div className="d-flex pt-3">
                  {[...Array(stars)].map((_, index) => {
                    const isFull = index < earnedStars && index < visibleStars
                    return (
                      <img
                        key={index}
                        src={isFull ? starFull : starEmpty}
                        alt="star"
                        className={cn('mx-2', { bounce: isFull })}
                      />
                    )
                  })}
                </div>
              </div>
            )}

            <div className="d-flex flex-column gap-2 mt-4 px-3 w-100">
              <QuizResultBar
                title={t('correctAnswers')}
                placeholder={`0 / ${quiz.questions}`}
                value={answersResult && `${quiz.correct_answers} / ${quiz.questions}`}
                progress={answersProgress && quiz.correct_answers / quiz.questions}
                color="green"
              />

              <QuizResultBar
                title={t('youEarned')}
                placeholder="0"
                value={earnedResult && quiz.earned_points}
                progress={earnedProgress && quiz.correct_answers / quiz.questions} // todo
                color="yellow"
              />
            </div>
          </div>
        </div>
      </div>

      {earnedStars > 0 ? (
        <div className="position-absolute bottom-0 w-100 p-3 mb-3 z-2">
          <button className="btn-action" onClick={onFinish}>
            {t('continue')}
          </button>
        </div>
      ) : (
        <div className="position-absolute bottom-0 w-100 d-flex gap-2 p-3 mb-3 z-2">
          <button className="btn-action-circle rounded-circle flex-shrink-0" onClick={onFinish}>
            <BackIcon fill="#000" />
          </button>
          <button className="btn-action flex-grow-1" onClick={onTryAgain}>
            {t('tryAgain')}
          </button>
        </div>
      )}

      {showConfetti && (
        <Animation
          name="QuizConfetti"
          loop={false}
          className="full-height-anim position-absolute w-100 h-100 start-0 bottom-0"
        />
      )}
    </div>
  )
}

export default QuizResult
