function Orbit({ radius, style }) {
  const diameter = 2 * radius
  return (
    <svg
      width={diameter}
      height={diameter}
      viewBox={`0 0 ${diameter} ${diameter}`}
      style={{
        ...style,
        overflow: 'visible',
        transition: 'all 0.5s ease'
      }}
    >
      <circle cx={radius} cy={radius} r={radius} stroke="white" opacity="0.5" strokeWidth="8" fill="none" />
    </svg>
  )
}

export default Orbit
