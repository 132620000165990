import './EnergyProgressBar.css'

import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import GradientBar from '@/components/energy/EnergyBar'
import ElementTooltip from '@/components/tooltip/ElementTooltip.jsx'

const _maxEnergy = 960

export const ENERGY_PROGRESS_HEIGHT = 32

function EnergyProgressBar({ energy, maxEnergy, allowTooltip = true }) {
  const { t } = useTranslation()
  const barRef = useRef(null)
  const [barWidth, setBarWidth] = useState(null)
  const [showEnergyTooltip, setShowEnergyTooltip] = useState(false)

  const handleEnergyClick = () => {
    if (allowTooltip) {
      setShowEnergyTooltip(true)
    }
  }

  useEffect(() => {
    const updateWidth = () => {
      if (barRef.current) {
        setBarWidth(barRef.current.offsetWidth)
      }
    }
    updateWidth()
    window.addEventListener('resize', updateWidth)
    return () => window.removeEventListener('resize', updateWidth)
  }, [energy, maxEnergy])

  const height = `${ENERGY_PROGRESS_HEIGHT}px`
  if (energy === undefined) {
    return <div className="placeholder-wave rounded-4 bg-dark w-100" style={{ height: height }} />
  }

  const percent = energy / (maxEnergy || _maxEnergy)
  let danger = energy === 0
  const energyColor = danger ? 'text-danger-pulse' : 'text-white'

  return (
    <div className="w-100 h-100" onClick={handleEnergyClick}>
      <div ref={barRef} className="position-relative d-flex h-100">
        <div className={`w-100 h-100 ${danger ? 'bg-danger-pulse' : 'bg-cl-dark2'}`}>
          {barWidth && <GradientBar totalWidth={barWidth} visibleWidth={barWidth * percent} />}
        </div>
        <div className="position-absolute d-flex justify-content-between align-items-center px-3 w-100 h-100 gap-1">
          <span className={`progress-text ${energyColor}`}>{t('energy')}</span>
          <span className={`progress-text flex-grow-1 text-center ${energyColor}`}>
            {Math.round(percent * 100) + '%'}
          </span>
        </div>
      </div>

      {showEnergyTooltip && (
        <ElementTooltip
          title={t('gas')}
          content={t('gasDescription')}
          onClose={() => {
            setShowEnergyTooltip(false)
          }}
          style={{
            top: '8px'
          }}
        />
      )}
    </div>
  )
}

export default EnergyProgressBar
