import { useCallback, useEffect, useState } from 'react'

import { BACKGROUND_PLANET_ASPECT } from '@/helpers/uiHelper.js'

function usePlanetBackground() {
  const calculate = useCallback(
    (width, height) => {
      return Math.sqrt(width ** 2 + height ** 2) * BACKGROUND_PLANET_ASPECT
    },
    [window]
  )

  const [size, setSize] = useState({
    width: calculate(window.innerWidth, window.innerHeight),
    height: calculate(window.innerWidth, window.innerHeight)
  })

  const updateSize = useCallback(() => {
    const diameter = calculate(window.innerWidth, window.innerHeight)
    setSize({
      width: diameter,
      height: diameter
    })
  }, [])

  useEffect(() => {
    window.addEventListener('resize', updateSize)

    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [updateSize])

  return size
}

export default usePlanetBackground
