export function FilledAcademyIcon({ size = 24, fill }) {
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.03874 3C9.64631 3 10.2209 3.27618 10.6005 3.75061L12 5.5L13.3995 3.75061C13.779 3.27618 14.3537 3 14.9612 3H20.5C20.7761 3 21 3.22386 21 3.5V15.5C21 15.7761 20.7761 16 20.5 16H14.9612C14.3537 16 13.779 16.2762 13.3995 16.7506L12.7809 17.5239C12.3805 18.0243 11.6194 18.0243 11.2191 17.5239L10.6005 16.7506C10.2209 16.2762 9.64631 16 9.03874 16H3.5C3.22386 16 3 15.7761 3 15.5V3.5C3 3.22386 3.22386 3 3.5 3L9.03874 3Z"
        fill={fill}
      />
      <path
        d="M3.5 18C3.22386 18 3 18.2239 3 18.5V19.5C3 19.7761 3.22386 20 3.5 20H8.08578L8.73222 20.6464C9.59889 21.5131 10.7743 22 12 22C13.2256 22 14.4011 21.5131 15.2678 20.6464L15.9142 20H20.5C20.7761 20 21 19.7761 21 19.5V18.5C21 18.2239 20.7761 18 20.5 18H15.5C15.2348 18 14.9804 18.1054 14.7929 18.2929L13.8535 19.2322C13.362 19.7238 12.6952 20 12 20C11.3048 20 10.638 19.7238 10.1464 19.2322L9.2071 18.2929C9.01956 18.1054 8.76521 18 8.49999 18H3.5Z"
        fill={fill}
      />
    </svg>
  )
}

export function BackIcon({ size = 24, fill }) {
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.29289 12.7071C1.90237 12.3166 1.90237 11.6834 2.29289 11.2929L7.29289 6.29289C7.68342 5.90237 8.31658 5.90237 8.70711 6.29289C9.09763 6.68342 9.09763 7.31658 8.70711 7.70711L5.41421 11L15 11C15.5523 11 16 11.4477 16 12C16 12.5523 15.5523 13 15 13L5.41421 13L8.70711 16.2929C9.09763 16.6834 9.09763 17.3166 8.70711 17.7071C8.31658 18.0976 7.68342 18.0976 7.29289 17.7071L2.29289 12.7071Z"
        fill={fill}
      />
      <path
        d="M15 4C14.4477 4 14 3.55228 14 3C14 2.44771 14.4477 2 15 2L19 2C20.6569 2 22 3.34314 22 5L22 19C22 20.6569 20.6569 22 19 22L15 22C14.4477 22 14 21.5523 14 21C14 20.4477 14.4477 20 15 20L19 20C19.5523 20 20 19.5523 20 19L20 5C20 4.44771 19.5523 4 19 4L15 4Z"
        fill={fill}
      />
    </svg>
  )
}
