import { useEffect, useState } from 'react'

export const useVisibilityAnimation = visible => {
  const [prevVisible, setPrevVisible] = useState(false)

  useEffect(() => {
    if (prevVisible === visible) return

    const timer = setTimeout(() => setPrevVisible(visible), 500)
    return () => clearTimeout(timer)
  }, [prevVisible, visible])

  return {
    shouldRender: visible || prevVisible,
    animationClass: visible ? 'showing-animation' : 'hiding-animation'
  }
}
