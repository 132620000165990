import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import checkIcon from '@/assets/check-active.svg'
import uwCoin from '@/assets/uw-coin-animated.gif'
import uwtIcon from '@/assets/uwt.svg'
import { ProfileItem } from '@/features/user/ProfileItem'
import { selectUser } from '@/features/user/usersSlice.js'

function Claim() {
  const { t } = useTranslation()
  const user = useSelector(selectUser)
  const claimDisabled = true

  return (
    <div className="pb-2">
      <div
        className="d-flex justify-content-between"
        style={{
          height: '256px'
        }}
      >
        <div className="row align-items-center ms-1">
          <div>
            <div className="h1 fw-bold">
              Unstoppable
              <br />
              Wallet Token
            </div>
            <span className="base-font text-white-50">UWT</span>
          </div>
        </div>
        <div
          style={{
            width: '256px',
            height: '256px',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${uwCoin})`,
            backgroundSize: 'cover',
            backgroundPosition: 'left 30px top'
          }}
        />
      </div>
      <div className="container">
        <div>
          <p className="base-font mt-4">{t('claimDescription')}</p>
        </div>
        <div className="bg-cl-dark rounded-4 mt-4">
          <ul className="list-group list-group-flush bg-transparent">
            <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center border-bottom border-cl-dark2 p-3">
              <span>{t('accessPremium')}</span>
              <img src={checkIcon} width={24} alt="check" className="ms-3" />
            </li>
            <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center border-bottom border-cl-dark2 p-3">
              <span>{t('voting')}</span>
              <img src={checkIcon} width={24} alt="check" className="ms-3" />
            </li>
            <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center p-3">
              <span>{t('exclusiveCrew')}</span>
              <img src={checkIcon} width={24} alt="check" className="ms-3" />
            </li>
            <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center p-3">
              <span>{t('airdrops')}</span>
              <img src={checkIcon} width={24} alt="check" className="ms-3" />
            </li>
          </ul>
        </div>
        <div className="base-font mt-4">
          <p>{t('timeToLearn')}</p>
          <p>{t('followOurNews')}</p>
        </div>
        <div className="bg-cl-dark1 rounded-4 my-4">
          <ProfileItem icon={uwtIcon} title={t('totalEarned')} value={user.points.toLocaleString() + ' UWT'} />
          <div className="px-3 pt-2 pb-3">
            <button className="btn-action" disabled>
              <div className="d-flex flex-row gap-1 justify-content-center align-items-center text-center">
                <span className="fs-5 fw-semibold">{t('claim')}</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Claim
