import './LevelBadge.scss'

import cn from 'clsx'
import { useMemo } from 'react'

const LevelBadge = ({ className, planetId, level, status, onClick, children }) => {
  const active = useMemo(() => {
    return status === 'completed' || status === 'current' || status === 'not-started' || status === 'not-passed'
  }, [status])

  return (
    <div
      className={cn(className, 'position-relative justify-content-center d-flex')}
      onClick={() => onClick && onClick(level)}
      style={{ width: '60px', height: '60px' }}
    >
      <button className={`btn-level ${planetId} w-100 pe-none`} disabled={!active}>
        {level}

        {children}
      </button>
    </div>
  )
}

export default LevelBadge
