import './Alert.css'

import { useEffect, useState } from 'react'

const AlertBase = ({ onClose, children }) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), 300)
    return () => clearTimeout(timer)
  }, [])

  const handleClose = e => {
    if (e) {
      e.stopPropagation()
    }
    setIsVisible(false)
    setTimeout(onClose, 300)
  }

  const handleContentClick = e => {
    e.stopPropagation() // This prevents the click from reaching the outer wrapper
  }

  return (
    <div
      className={`position-absolute alert-wrapper bg-black bg-opacity-50 vw-100 vh-100 p-4 z-3`}
      style={{ opacity: isVisible ? 1 : 0 }}
      onClick={handleClose}
    >
      <div
        className={`visible alert-wrapper w-100 rounded-5 bg-cl-dark2 text-white gap-4 p-4`}
        style={{ marginBottom: '76px' }} //move popup higher for height of footer to make it look centered
        onClick={handleContentClick}
      >
        {children}
      </div>
    </div>
  )
}

export default AlertBase
