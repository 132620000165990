import './DragSpaceshipAnimation.css'

import cn from 'clsx'

import spaceshipIcon from '/unstoppableship.png'
import { STATUS } from '@/components/drag-slider/DragSliderAnimationLine.js'

function DragSpaceshipAnimation({ status }) {
  return (
    <div
      className={cn('spaceship-container', {
        flying: [
          STATUS.growToLevel,
          STATUS.showLevels,
          STATUS.hideLevels,
          STATUS.selectLevels,
          STATUS.reduceToLevels
        ].includes(status),
        landing: [STATUS.landingShip, STATUS.background].includes(status),
        goUp: [STATUS.growToBackground].includes(status),
        goBack: [STATUS.reduceToPlanet].includes(status)
      })}
    >
      <img
        className={cn('spaceship', {
          animated: [STATUS.growToLevel, STATUS.selectLevels, STATUS.showLevels, STATUS.hideLevels].includes(status),
          comeIn: [STATUS.landingShip].includes(status),
          hidden: [STATUS.growToBackground, STATUS.background].includes(status),
          comeOut: [STATUS.showLevels, STATUS.selectLevels, STATUS.reduceToLevels].includes(status)
        })}
        src={spaceshipIcon}
        alt="spaceship"
        width={93}
        height={60}
      />
    </div>
  )
}

export default DragSpaceshipAnimation
