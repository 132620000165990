import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import closeIcon from '@/assets/close-popup-icon.svg'
import present from '@/assets/Present.png'
import AlertBase from '@/components/alert/AlertBase.jsx'
import Animation from '@/components/animation/Animation.jsx'

function TaskRewardAlert({ rewardAmount, onClose }) {
  const { t } = useTranslation()
  const [showConfetti, setShowConfetti] = useState(false)

  useEffect(() => {
    const showTimer = setTimeout(() => {
      setShowConfetti(true)

      const hideTimer = setTimeout(() => {
        setShowConfetti(false)
      }, 4000) // time to hide confetti layer, to enable click areas to work

      return () => clearTimeout(hideTimer)
    }, 500) // time to show confetti animation

    return () => clearTimeout(showTimer)
  }, [])

  return (
    <div className="position-fixed w-100 h-100 top-50 start-50 translate-middle">
      <AlertBase onClose={onClose}>
        <div className="d-flex align-items-center w-100">
          <div style={{ width: '24px' }}></div>
          <span className="h5 text-center flex-grow-1 mx-1" style={{ marginBottom: '0' }}>
            {t('successExclamation')}
          </span>
          <img onClick={onClose} src={closeIcon} style={{ width: '24px', height: '24px' }} className="text-gray-500" />
        </div>
        <div className="d-flex flex-column align-items-center">
          <Animation className="s78 text-center" name="Present" placeholder={present} />
          <p className="mt-4 base-font text-center">{t('youHaveEarnedTokens')}</p>
          <p className="mt-1 fs-2 mb-0 fw-bold text-center text-warning">{rewardAmount}</p>
        </div>
      </AlertBase>
      {showConfetti && (
        <Animation
          name="QuizConfetti"
          loop={false}
          className="full-height-anim position-absolute w-100 h-100 start-0 bottom-0"
          style={{
            zIndex: '4',
            pointerEvents: 'none'
          }}
        />
      )}
    </div>
  )
}

export default TaskRewardAlert
