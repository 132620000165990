import { marked } from 'marked'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const MarkdownViewer = () => {
  const { url } = useParams()
  const [content, setContent] = useState('')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchContent = async () => {
      try {
        setLoading(true)
        const response = await fetch(url)
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        const text = await response.text()
        setContent(text)
      } catch (err) {
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    if (url) {
      fetchContent()
    }
  }, [url])

  const renderMarkdown = markdown => {
    const rawHtml = marked(markdown, {
      breaks: true,
      gfm: true
    })
    return { __html: rawHtml }
  }

  if (loading) {
    return (
      <div className="d-flex justify-content-center p-5">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )
  }

  if (error) {
    return (
      <div className="alert alert-danger m-3" role="alert">
        Error loading content: {error}
      </div>
    )
  }

  return (
    <div className="container my-4">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="Markdown" dangerouslySetInnerHTML={renderMarkdown(content)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MarkdownViewer
