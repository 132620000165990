import './DragSlider.scss'
import './SliderButtons.css'
import './planet-stats/PlanetStats.css'

import cn from 'clsx'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import PlanetLockedAlert from '@/components/alert/PlanetLockedAlert.jsx'
import { TopicsLinksBottomSheet } from '@/components/bottom-sheet/TopicsLinksBottomSheet.jsx'
import ChangeButton from '@/components/drag-slider/ChangeButton.jsx'
import {
  selectAnimationDeltaCenter,
  selectLevelIndex,
  selectLocked,
  selectPlanetIndex,
  selectSelectedPlanet,
  selectTopics,
  selectTouchStartTime,
  selectXCoordinate,
  setMultiple,
  setTopics
} from '@/components/drag-slider/DragSlice.js'
import {
  animationLine,
  classNameByStatus,
  levelStatuses,
  STATUS
} from '@/components/drag-slider/DragSliderAnimationLine.js'
import PlanetStats from '@/components/drag-slider/planet-stats/PlanetStats.jsx'
import DragSpaceshipAnimation from '@/components/drag-slider/spaceship/DragSpaceshipAnimation.jsx'
import PlanetBackground from '@/components/planet/PlanetBackground.jsx'
import { TOP_BAR_HEIGHT } from '@/features/home/HomeTopBar.jsx'
import Levels from '@/features/planet/planet/Levels.jsx'
import { completedCount, currentLevel, earned, finished, placeholder, totalBalance } from '@/helpers/planetHelper.js'
import { correctedAngle, inRect, levelPlanetScale, sizes } from '@/helpers/uiHelper.js'
import usePlanetBackground from '@/hooks/usePlanetBackground.js'
import { useVisibilityAnimation } from '@/hooks/useVisibilityAnimation.js'
import useWindowSize from '@/hooks/useWindowSize.js'

const ANIMATION_TIME = 600
const TAP_THRESHOLD = 5
const TAP_TIMEOUT = 200
const ORBIT_RADIUS = 0.7
const BASE_PLANET = 'baselion'

const DragSlider = ({ loading, planets, contentInsets, showSlideButtons = true, children }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const root = document.querySelector(':root')
  const sliderRef = useRef()
  const windowSize = useWindowSize()
  const backgroundSize = usePlanetBackground()

  const screenBackground = () => status[0] === STATUS.background

  const homeSceneIndex = useMemo(() => {
    const path = location.pathname.split('/')
    if (loading || planets.length === 0) {
      // empty screen (loading)
      return 1
    } else if (path.length === 2) {
      // show planets slider
      return path[1] === '' ? 2 : 1
    } else if (path.length === 3) {
      // show level selector
      return path[1] === 'planet' ? 3 : 1
    } else if (path.length > 3) {
      // show any planet screens with background only
      return path[1] === 'planet' ? 4 : 1
    }
  }, [loading, planets, location.pathname])

  const planetIndex = useSelector(selectPlanetIndex)
  const xCoordinate = useSelector(selectXCoordinate)
  const touchStartTime = useSelector(selectTouchStartTime)
  const locked = useSelector(selectLocked)
  const animationDeltaCenter = useSelector(selectAnimationDeltaCenter)
  const selectedPlanet = useSelector(selectSelectedPlanet)
  const levelIndex = useSelector(selectLevelIndex)
  const topics = useSelector(selectTopics)

  const [status, setStatus] = useState([STATUS.empty])
  const [lockedPlanetExplanation, setLockedPlanetExplanation] = useState(null)

  const getPreviousPlanet = useCallback(
    currentIndex => {
      if (currentIndex === 0) return planets[0]

      for (let i = currentIndex - 1; i >= 0; i--) {
        if (planets[i].enabled) {
          return planets[i]
        }
      }
      return planets[0]
    },
    [planets]
  )

  function planetEnabled(planetIndex, planet) {
    const previousPlanet = getPreviousPlanet(planetIndex)
    return planet.id === BASE_PLANET || finished(previousPlanet)
  }

  const handlePlanetClick = useCallback(
    planetIndex => {
      const planet = planets[planetIndex]
      const enabled = planetEnabled(planetIndex, planet)

      if (planet.enabled && enabled) {
        navigate(`/planet/${planet.id}`)
      } else {
        const previousPlanet = getPreviousPlanet(planetIndex)
        setLockedPlanetExplanation({ previousPlanet: previousPlanet.name, thisPlanet: planet.name })
      }
    },
    [navigate, planets]
  )

  /*Planet selector states*/
  const activeIndex = Math.max(0, Math.min(planetIndex - Math.round(animationDeltaCenter), planets.length - 1))
  const planetSizes = sizes(windowSize, contentInsets)

  /*Level selector states*/
  const planetHeaderVisibility = useVisibilityAnimation(status[0] === STATUS.selectPlanet)
  const levelVisibility = useVisibilityAnimation(status[0] === STATUS.showLevels || status[0] === STATUS.selectLevels)
  const childVisibility = useVisibilityAnimation(status[0] === STATUS.background)

  const getEventCoordinate = e => (e.changedTouches ? e.changedTouches[0] : e).clientX

  const lock = e => {
    if (screenBackground()) {
      e.preventDefault()
      return
    }
    dispatch(setMultiple({ xCoordinate: getEventCoordinate(e), touchStartTime: Date.now(), locked: true }))
  }

  const drag = e => {
    e.preventDefault()
    if (locked) {
      const xDelta = Math.round(getEventCoordinate(e) - xCoordinate)
      root.style.setProperty('--duration', '30ms')

      let isFirst, isLast
      if (selectedPlanet) {
        // calculate for select levels
        const levelIndex = getComputedStyle(root).getPropertyValue('--levelIndex')

        isLast = selectedPlanet.quizzes.length === parseFloat(levelIndex) + 1
        isFirst = parseFloat(levelIndex) === 0
      } else {
        // calculate for select planets
        isLast = planets.length === planetIndex + 1
        isFirst = planetIndex === 0

        // Limiting movement to the outermost slides
        const maxDelta = (planets.length - 1) * windowSize.width
        const newPosition = Math.max(Math.min(xDelta - planetIndex * windowSize.width, 0), -maxDelta)
        root.style.setProperty('--planetTranslateX', `${newPosition}px`)
      }

      const minimize = (isFirst && xDelta > 0) || (isLast && xDelta < 0)
      const animationDelta = (xDelta / windowSize.width) * (minimize ? 0.1 : 1)
      const absAnimationDelta = Math.abs(animationDelta)

      root.style.setProperty('--swipeDelta', `${animationDelta}`)
      root.style.setProperty('--absSwipeDelta', `${absAnimationDelta}`)
    }
  }

  const move = e => {
    if (locked) {
      const deltaX = getEventCoordinate(e) - xCoordinate
      const direction = Math.sign(deltaX)
      const animationDelta = deltaX / windowSize.width
      const absAnimationDelta = Math.abs(animationDelta)

      /*Handle tap on screen*/
      if (
        !loading &&
        !selectedPlanet &&
        !screenBackground() &&
        Math.abs(deltaX) < TAP_THRESHOLD &&
        Date.now() - touchStartTime < TAP_TIMEOUT
      ) {
        if (inRect(e, windowSize, planetSizes.aspectSize)) {
          handlePlanetClick?.(planetIndex)
        }

        dispatch(setMultiple({ xCoordinate: null, animationDeltaCenter: 0, touchStartTime: 0, locked: false }))
        return
      }

      let duration
      let newIndex
      let maxIndex
      if (selectedPlanet) {
        newIndex = parseFloat(getComputedStyle(root).getPropertyValue('--levelIndex'))
        maxIndex = selectedPlanet.quizzes.length - 1
      } else {
        newIndex = planetIndex
        maxIndex = planets.length - 1
      }

      /*Handle finish drag*/
      if (absAnimationDelta > 0.1) {
        // Calculate the remaining distance and animation time
        duration = (1 - absAnimationDelta) * ANIMATION_TIME

        // Changing the planetIndex depending on the direction
        if (direction > 0 && newIndex > 0) {
          newIndex -= 1
        } else if (direction < 0 && newIndex < maxIndex) {
          newIndex += 1
        }
      } else {
        duration = absAnimationDelta * ANIMATION_TIME * 2
      }

      switchIndex(newIndex, duration)
    }
  }

  const switchIndex = (index, duration = ANIMATION_TIME) => {
    root.style.setProperty('--duration', `${duration}ms`)
    root.style.setProperty('--swipeDelta', '0')
    root.style.setProperty('--absSwipeDelta', '0')

    if (selectedPlanet) {
      const itemAngle = getComputedStyle(root).getPropertyValue('--levelAngle')
      const finalPosition = -index * parseFloat(itemAngle)

      root.style.setProperty('--planetTranslateAngle', `${finalPosition}deg`)
      root.style.setProperty('--levelIndex', `${index}`)
      dispatch(
        setMultiple({
          xCoordinate: null,
          touchStartTime: 0,
          animationDeltaCenter: 0,
          locked: false,
          levelIndex: index,
          delta: 0
        })
      )
    } else {
      const finalPosition = -index * windowSize.width
      root.style.setProperty('--planetTranslateX', `${finalPosition}px`)

      localStorage.setItem(`current-planet-index`, index)
      dispatch(
        setMultiple({
          xCoordinate: null,
          touchStartTime: 0,
          animationDeltaCenter: 0,
          locked: false,
          planetIndex: index,
          delta: 0
        })
      )
    }
  }

  /* determine scene status when change screen */
  useEffect(() => {
    const lastScene = parseFloat(getComputedStyle(root).getPropertyValue('--lastHomeScene'))
    root.style.setProperty('--lastHomeScene', `${homeSceneIndex}`)

    const newStatusLine = animationLine(lastScene, homeSceneIndex)
    setStatus(newStatusLine)
  }, [homeSceneIndex])

  useEffect(() => {
    // no changes if last status
    if (status.length <= 1) return

    if (status[0].duration === 0) {
      setStatus(prevItems => (prevItems.length > 1 ? prevItems.slice(1) : prevItems))
      return
    }

    setTimeout(() => {
      setStatus(prevItems => (prevItems.length > 1 ? prevItems.slice(1) : prevItems))
    }, status[0].duration)
  }, [status])

  useEffect(() => {
    if (status[0] === STATUS.growToLevel || status[0] === STATUS.reduceToLevels) {
      setupSelectLevels(status[0] === STATUS.growToLevel)
    } else if (status[0] === STATUS.growToBackground) {
      startShowBackground()
    } else if (status[0] === STATUS.reduceToPlanet || status[0] === STATUS.selectPlanet) {
      startSelectPlanets()
    }
  }, [status[0]])

  const setupSelectLevels = showCurrent => {
    const planet = planets[planetIndex]

    if (!planet) return
    // get right scale and resize planet to show in bottom
    const scale = levelPlanetScale(planetSizes.planetSize, windowSize.height)

    // planets
    root.style.setProperty('--duration', `${ANIMATION_TIME}ms`)
    root.style.setProperty('--planetScale', `${scale}`)

    // levels
    let currentIndex
    if (showCurrent) {
      currentIndex = currentLevel(planet) ?? planet.quizzes.length - 1
    } else {
      currentIndex = getComputedStyle(root).getPropertyValue('--levelIndex')
    }
    root.style.setProperty('--levelIndex', `${currentIndex}`)
    root.style.setProperty('--levelCount', `${planet.quizzes.length}`)

    const angle = correctedAngle(windowSize.width, windowSize.height * ORBIT_RADIUS, planet.quizzes.length)
    root.style.setProperty('--levelAngle', `${angle}deg`)

    /*set current level in center*/
    root.style.setProperty('--planetTranslateAngle', `-${currentIndex * angle}deg`)

    dispatch(setMultiple({ levelIndex: currentIndex, selectedPlanet: planet }))
  }

  const startSelectPlanets = () => {
    // planets
    root.style.setProperty('--duration', `${ANIMATION_TIME}ms`)
    root.style.setProperty('--planetScale', '0')

    // levels
    root.style.setProperty('--levelIndex', `0`)
    root.style.setProperty('--levelCount', `0`)

    dispatch(setMultiple({ levelIndex: 0, selectedPlanet: null }))
  }

  const startShowBackground = () => {
    const backgroundScale = Math.max(backgroundSize.width, backgroundSize.height) / planetSizes.aspectSize

    // planets
    root.style.setProperty('--duration', `${ANIMATION_TIME}ms`)
    root.style.setProperty('--planetScale', `${backgroundScale}`)
  }

  useEffect(() => {
    root.style.setProperty('--screenWidth', `${windowSize.width}`)
    root.style.setProperty('--screenHeight', `${windowSize.height}`)

    const prevTX = getComputedStyle(root).getPropertyValue('--planetTranslateX')
    const coefficient = parseFloat(prevTX) / windowSize.prevWidth
    const newTX = windowSize.width * coefficient

    root.style.setProperty('--swipeDelta', '0')
    root.style.setProperty('--absSwipeDelta', '0')
    root.style.setProperty('--planetTranslateX', `${newTX || 0}px`)
  }, [windowSize])

  useEffect(() => {
    const container = sliderRef.current
    container.addEventListener('mousedown', lock)
    container.addEventListener('touchstart', lock)
    container.addEventListener('mousemove', drag)
    container.addEventListener('touchmove', drag)
    container.addEventListener('mouseup', move)
    container.addEventListener('touchend', move)

    return () => {
      container.removeEventListener('mousedown', lock)
      container.removeEventListener('touchstart', lock)
      container.removeEventListener('mousemove', drag)
      container.removeEventListener('touchmove', drag)
      container.removeEventListener('mouseup', move)
      container.removeEventListener('touchend', move)
    }
  }, [locked, xCoordinate])

  useEffect(() => {
    const finalPosition = -planetIndex * windowSize.width
    root.style.setProperty('--planetTranslateX', `${finalPosition}px`)
  }, [])

  const onChange = shift => {
    const newIndex = planetIndex + shift
    switchIndex(newIndex)
  }

  const hideButtons = locked || selectedPlanet || !showSlideButtons || screenBackground()

  const handleLevelStart = () => {
    if (playShown) return
    localStorage.setItem('playShown', 'true')
  }

  const PlanetHeader = useMemo(() => {
    const planet = planets[planetIndex]
    if (!planet || !planetHeaderVisibility.shouldRender) return null

    return (
      <div
        className={cn('planet-title-container d-flex flex-column px-5', planetHeaderVisibility.animationClass)}
        style={{ marginTop: TOP_BAR_HEIGHT + 24 }}
      >
        <span key={planet.name} className="h4">
          {planet.name}
        </span>
        <span key={planet.info} className="planet-title-description base-font">
          {planet.info}
        </span>
      </div>
    )
  }, [planets, planetIndex, planetHeaderVisibility.shouldRender, planetHeaderVisibility.animationClass])

  const Stats = useMemo(() => {
    const planet = planets[planetIndex]
    if (!planet || !planetHeaderVisibility.shouldRender) return null

    return (
      <div className={cn('planet-stats-container pe-none', planetHeaderVisibility.animationClass)}>
        <PlanetStats
          level={completedCount(planet)}
          maxLevel={planet.quizzes.length}
          balance={earned(planet)}
          maxBalance={totalBalance(planet)}
        />
      </div>
    )
  }, [planetIndex, planets, planetHeaderVisibility.shouldRender, planetHeaderVisibility.animationClass])

  const isLanding = levelStatuses.includes(status[0])
  const playShown = localStorage.getItem('playShown') || false
  const showPlay =
    status[0] === STATUS.selectPlanet && planetIndex === 0 && !playShown && planets?.[0]?.quizzes?.[0]?.answers === 0

  return (
    <div className="planet-container" style={{ backgroundPosition: `0px ${isLanding ? '-50' : '0'}px` }}>
      {/*Planet texts*/}
      {PlanetHeader}
      {/*planet stats*/}
      {Stats}

      {/*loading placeholder*/}
      {(loading || status[0] === STATUS.empty) && (
        <div className="vw-100 vh-100 d-flex align-items-center justify-content-center">
          <img
            src={placeholder(planetIndex)}
            width={planetSizes.aspectSize * 0.4}
            height={planetSizes.aspectSize * 0.4}
            style={{
              opacity: 0.5
            }}
            alt="loading"
          />
        </div>
      )}

      {/*buttons*/}
      <ChangeButton
        direction={'left'}
        hide={hideButtons}
        disabled={planetIndex === 0}
        onClick={() => {
          onChange(-1)
        }}
      />
      <ChangeButton
        direction={'right'}
        hide={hideButtons}
        disabled={planetIndex >= planets.length - 1}
        onClick={() => {
          onChange(1)
        }}
      />
      {childVisibility.shouldRender && (
        <div className={cn('z-2 position-absolute w-100 h-100', childVisibility.animationClass)}>{children}</div>
      )}

      {/*spaceship*/}
      <DragSpaceshipAnimation status={status[0]}></DragSpaceshipAnimation>

      {/*planet slides*/}
      <div
        ref={sliderRef}
        className={cn('planet-slider')}
        style={{
          '--planetCount': planets.length
        }}
      >
        {planets.map((src, i) => {
          let active = i === activeIndex
          if (status[0] === STATUS.initialGrowPlanet || status[0] === STATUS.empty) {
            // disable planets before initial loading finished
            active = false
          }

          const size = {
            width: planetSizes.aspectSize,
            height: planetSizes.aspectSize
          }

          return (
            <div key={i} className="planet-slider-item-container">
              {levelVisibility.shouldRender && i === planetIndex && (
                <Levels
                  planet={src}
                  radius={windowSize.height * ORBIT_RADIUS}
                  levelIndex={levelIndex}
                  className={levelVisibility.animationClass}
                  status={status}
                  setStatus={setStatus}
                  startClicked={handleLevelStart}
                  onOpenTopics={topics => {
                    dispatch(setTopics(topics))
                  }}
                />
              )}

              <PlanetBackground
                key={i}
                planet={src}
                active={active}
                disabled={!planetEnabled(i, src)}
                showState={[STATUS.selectPlanet, STATUS.reduceToPlanet].includes(status[0])}
                showGlow={isLanding}
                showPlayText={showPlay}
                size={size}
                className={classNameByStatus(status, i - planetIndex)}
              />
            </div>
          )
        })}
      </div>

      {lockedPlanetExplanation && (
        <PlanetLockedAlert
          previousPlanet={lockedPlanetExplanation.previousPlanet}
          thisPlanet={lockedPlanetExplanation.thisPlanet}
          onClose={() => {
            setLockedPlanetExplanation(null)
          }}
        />
      )}
      {topics && (
        <TopicsLinksBottomSheet
          levelIndex={levelIndex}
          topics={topics}
          handleClose={() => {
            dispatch(setTopics(null))
          }}
        />
      )}
    </div>
  )
}

export default DragSlider
