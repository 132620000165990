import { useEffect, useState } from 'react'

const QuizTimer = ({ questionIndex, questionCount, started, onFinish }) => {
  const duration = 60 // Total duration in seconds

  const [timeLeft, setTimeLeft] = useState(duration)
  const [startTime, setStartTime] = useState(null)

  useEffect(() => {
    if (!started) return

    let timerStartTime = startTime

    if (!timerStartTime) {
      timerStartTime = Date.now()
      setStartTime(timerStartTime)
    }

    const updateTimer = () => {
      const elapsedTime = Math.floor((Date.now() - timerStartTime) / 1000)
      const remainingTime = duration - elapsedTime

      if (remainingTime <= 0) {
        setTimeLeft(0)
        onFinish()
        return
      }

      setTimeLeft(remainingTime)
    }

    // Update the timer at regular intervals
    const timer = setInterval(updateTimer, 1000)

    // Cleanup the interval on unmount or dependency change
    return () => clearInterval(timer)
  }, [started, startTime, onFinish])

  useEffect(() => {
    // Reset timer when `questionIndex` changes
    setTimeLeft(duration)
    setStartTime(null)
  }, [questionIndex])

  return (
    <div className="d-flex justify-content-center align-items-center w-100" style={{ height: 28 }}>
      <div className="fs-7 fw-bold me-3">
        {questionIndex + 1} / {questionCount}
      </div>
      <div className="rounded-1 bg-grey" style={{ width: 140, height: 4 }}>
        <div
          className="rounded-1 bg-yellow"
          style={{ width: `${(timeLeft / duration) * 100}%`, height: 4, transition: 'width 250ms linear' }}
        />
      </div>
      <div className="fs-7 ms-3 text-end" style={{ width: 16 }}>
        {timeLeft}
      </div>
    </div>
  )
}

export default QuizTimer
