import './App.css'

import { Outlet, useLocation } from 'react-router-dom'

import { BackButtonHandler } from '@/components/BackButtonHandler'
import { Footer } from '@/components/footer/Footer'
import { ScrollToTop } from '@/components/ScrollToTop'

function App() {
  const { pathname } = useLocation()

  const footerVisible = ['/', '/tasks', '/user', '/rank'].includes(pathname)
  const ignoreFooter = ['/'].includes(pathname)
  let height = 'var(--safe-area-inset-bottom)'
  if (footerVisible && !ignoreFooter) {
    height += ' + var(--footer-height)'
  }

  const paddingBottom = `calc(${height})`

  return (
    <div className="App-container user-select-none">
      <div className="App" style={{ paddingBottom }} id="app">
        <BackButtonHandler />
        <ScrollToTop />
        <Outlet />
        <Footer visible={footerVisible} />
      </div>
    </div>
  )
}

export default App
