import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchTasks, selectTask, Tasks } from '@/features/tasks/tasksSlice.js'

const checkingTime = 4 * 60 * 1000 //4 min
const checkingInterval = 4 * 1000 //4 sec

function InstallWalletTimer({ startFrom, onComplete }) {
  const dispatch = useDispatch()
  const task = useSelector(state => selectTask(state, Tasks.installWallet))
  const [timeLeft, setTimeLeft] = useState(undefined)

  const isStarted = startFrom !== 0

  const currentTime = Date.now()
  const currentTimeLeft = isStarted ? checkingTime - currentTime + startFrom : undefined

  const installCompleted = task && task.completed

  useEffect(() => {
    if (!isStarted) return

    if (timeLeft <= 0 || installCompleted) {
      onComplete(installCompleted)
      return
    }

    const intervalId = setInterval(() => {
      dispatch(fetchTasks())
      setTimeLeft(prevTime => {
        if (!prevTime) return currentTimeLeft
        return prevTime - checkingInterval
      })
    }, checkingInterval)

    return () => clearInterval(intervalId)
  }, [timeLeft, task, onComplete])

  return null
}

export default InstallWalletTimer
