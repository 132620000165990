import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import Loader from '@/components/loader/Loader'
import MarkdownViewer from '@/components/markdown/MarkdownViewer'
import App from '@/features/app/App'
import Claim from '@/features/claim/Claim'
import Home from '@/features/home/Home'
import Rank from '@/features/home/Rank'
import Quiz from '@/features/planet/quiz/Quiz.jsx'
import RouteError from '@/features/RouteError'
import Settings from '@/features/settings/Settings'
import TaskList from '@/features/tasks/TaskList'
import { Profile } from '@/features/user/Profile'
import UserProfile from '@/features/user/UserProfile.jsx'

const main = <Home />
const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <RouteError />,
    children: [
      { index: true, element: main },
      {
        path: '/planet',
        element: main,
        children: [
          { path: ':planetId', element: null },
          { path: ':planetId/:level/quiz', element: <Quiz /> }
        ]
      },
      { path: '/rank', element: <Rank /> },
      { path: '/settings', element: <Settings /> },
      { path: '/tasks', element: <TaskList /> },
      { path: '/user', element: <UserProfile /> },
      { path: '/claim', element: <Claim /> },
      { path: '/markdown/:url', element: <MarkdownViewer /> },
      { path: '/profile/:id', element: <Profile /> }
    ]
  }
])

export function Routes() {
  return <RouterProvider router={router} fallbackElement={<Loader />} />
}
