const apiUrl = `${import.meta.env.VITE_API_URL || '/api/v1'}`

const request = (url, init) => {
  const token = localStorage.getItem('jwt')
  const headers = {
    'Content-Type': 'application/json',
    ...(token && { Authorization: `Bearer ${token}` })
  }

  return fetch(`${apiUrl}${url}`, { ...init, headers }).then(async res => {
    if (res.ok) {
      return res.json()
    }

    return Promise.reject(await res.json())
  })
}

export function postAnswer(quizId, questionId, answers) {
  return request(`/quiz/${quizId}`, {
    method: 'POST',
    body: JSON.stringify({
      question_id: questionId,
      answer: answers
    })
  })
}

export function getQuizPlanets() {
  return request('/planets/')
}

export function getTasks() {
  return request('/tasks')
}

export function getStats() {
  return request('/users/stats')
}

export function getProfile() {
  return request('/users/profile')
}

export function getUser(id) {
  return request(`/users/${id}`)
}

export function getFriends() {
  return request('/users/friends')
}

export function getOAuthJWTToken(taskId, languageCode) {
  return request(`/auth/oauth-jwt-token?task_id=${taskId}&language_code=${languageCode}`)
}

export function twitterAuthorizationLink(jwt) {
  return `${apiUrl}/twitter/connect?token=${jwt}`
}

export function youtubeAuthorizationLink(jwt) {
  return `${apiUrl}/youtube/auth?token=${jwt}`
}

export function authenticate(initData, referralCode, partnerId, customLanguage) {
  const params = { initData }
  if (referralCode) {
    params.referralCode = referralCode
  }
  if (partnerId) {
    params.partnerId = partnerId
  }
  if (customLanguage) {
    params.customLanguage = customLanguage
  }

  return request('/auth', { method: 'POST', body: JSON.stringify(params) })
}
