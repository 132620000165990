import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { socket } from '@/api/socket.js'
import present from '@/assets/Present.png'
import AlertBase from '@/components/alert/AlertBase.jsx'
import Animation from '@/components/animation/Animation.jsx'
import { selectUser } from '@/features/user/usersSlice.js'

const rewardTitle = (title, bonus) => {
  return (
    <>
      <span className="h3 mb-0 text-white">{title}</span>
      <span className="h3 mb-0 text-warning">{bonus}</span>
    </>
  )
}

function PartnerRewardAlert({ onClose }) {
  const { t } = useTranslation()

  const user = useSelector(selectUser)

  let partner = useMemo(() => {
    if (!user) return null

    let params = {
      id: user.referrer_partner_id,
      userId: user.id
    }

    switch (params.id) {
      case 'blum':
        params.title = rewardTitle(t('blumBonus'), '+400 UWT')
        params.description = t('blumDescription')
        params.bonus = 400
        break
    }
    return params
  }, [user])

  if (!user?.referrer_partner_id) return null
  const handleCloseRewardAlert = () => {
    if (partner && partner.bonus) {
      socket.emit('task-open', { taskId: `${user.referrer_partner_id}-welcome-reward`, userId: partner.userId })
    }
    onClose()
  }

  return (
    <AlertBase onClose={handleCloseRewardAlert}>
      <div className={`visible alert-wrapper w-100 rounded-5 bg-cl-dark2 text-white gap-4 p-4`}>
        <div className="alert-wrapper gap-3 base-font">
          <Animation className="s78" name="Present" placeholder={present} />
          {partner.title}
        </div>
        <span className="fs-6 text-white text-center base-font">{partner.description}</span>
        <button className="btn-action w-100 fw-semibold" onClick={handleCloseRewardAlert}>
          {t('thanks')}
        </button>
      </div>
    </AlertBase>
  )
}

export default PartnerRewardAlert
