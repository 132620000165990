import './ElementTooltip.css'

import { useState } from 'react'

import closeIcon from '@/assets/close-popup-icon.svg'

function ElementTooltip({ title, content, style, onClose }) {
  const [isVisible, setIsVisible] = useState(true)

  const onTap = () => {
    setIsVisible(false)
    setTimeout(() => {
      onClose && onClose()
    }, 300)
  }

  const handleContentClick = e => {
    e.stopPropagation() // This prevents the click from reaching the outer wrapper
  }

  return (
    <div className={`position-fixed start-0 alert-wrapper vw-100 vh-100`} onClick={onTap}>
      <div
        className={`tooltip-wrapper ${isVisible ? 'show' : 'hide'} d-flex flex-column align-items-end`}
        style={style}
        onClick={handleContentClick}
      >
        <div
          className="bg-cl-dark2"
          style={{
            position: 'absolute',
            right: '68px',
            transform: 'rotate(45deg)',
            width: '16px',
            height: '16px'
          }}
        />
        <div
          className={`rounded-5 bg-cl-dark2 text-white d-flex flex-column flex-grow-1 mx-3 px-4 pb-3 pt-4 mt-2`}
          style={{
            maxWidth: '450px'
          }}
        >
          <div className="d-flex align-items-center">
            <div style={{ width: '24px' }}></div>
            <span className="h5 text-center flex-grow-1" style={{ marginBottom: '0' }}>
              {title}
            </span>
            <img
              onClick={onClose}
              alt="close"
              src={closeIcon}
              style={{ width: '24px', height: '24px' }}
              className="text-gray-500"
            />
          </div>
          <p className="base-font mt-4">{content}</p>
        </div>
      </div>
    </div>
  )
}

export default ElementTooltip
