import { getTasks } from '@/api/api'
import { createSlice } from '@reduxjs/toolkit'

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState: {
    list: {},
    status: 'idle' // fetching/fetched/failed
  },
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload
    },
    setTasks: (state, action) => {
      const list = action.payload
      state.list = list.reduce((a, b) => ({ ...a, [b.id]: b }), {})
      state.status = 'fetched'
    },
    setCompleted: (state, action) => {
      const task = state.list[action.payload]
      task.completed = true
    }
  },
  selectors: {
    selectStatus: tasks => tasks.status,
    selectList: tasks => tasks.list,
    selectTask: (tasks, taskId) => tasks.list[taskId]
  }
})

export const { setStatus, setTasks, setCompleted } = tasksSlice.actions

export const { selectStatus, selectList, selectTask } = tasksSlice.selectors

export const fetchTasks = () => async (dispatch, getState) => {
  const status = selectStatus(getState())

  if (status === 'fetching') {
    return
  }

  dispatch(setStatus('fetching'))

  try {
    const data = await getTasks()
    dispatch(setTasks(data))
  } catch (err) {
    console.log(err)
    dispatch(setStatus('failed'))
  }
}

export const Tasks = {
  welcomeReward: 'welcome-reward',
  joinCommunity: 'join-community',
  joinCommunityRu: 'join-community-ru',
  shareStory: 'share-story',
  installWallet: 'install-wallet',
  followCommunityX: 'follow-community-x',
  followCommunityXVerified: 'follow-community-x-verified',
  followCommunityXRU: 'follow-community-x-ru',
  retweetOnX: 'retweet-on-x',
  subscribeUwYoutube: 'subscribe-uw-youtube',
  subscribeUwYoutubeVerified: 'subscribe-uw-youtube-verified',
  boostCommunityChannel: 'boost-community-channel',
  invite5Friend: 'invite-5-friend',
  invite10Friend: 'invite-10-friend',
  invite25Friend: 'invite-25-friend'
}

export const TaskGroups = {
  becomeOneOfUs: [
    Tasks.welcomeReward,
    Tasks.joinCommunity,
    Tasks.followCommunityX,
    Tasks.followCommunityXRU,
    Tasks.subscribeUwYoutube,
    Tasks.joinCommunityRu,
    Tasks.installWallet
  ],
  showThatYouCare: [Tasks.shareStory, Tasks.boostCommunityChannel],
  forTrueSupporters: [Tasks.invite5Friend, Tasks.invite10Friend, Tasks.invite25Friend],
  specialBond: [Tasks.followCommunityXVerified, Tasks.subscribeUwYoutubeVerified]
}

export const alwaysClickableIds = new Set([
  Tasks.joinCommunity,
  Tasks.joinCommunityRu,
  Tasks.followCommunityX,
  Tasks.followCommunityXVerified,
  Tasks.followCommunityXRU,
  Tasks.boostCommunityChannel,
  Tasks.installWallet,
  Tasks.shareStory,
  Tasks.subscribeUwYoutube,
  Tasks.subscribeUwYoutubeVerified
])

export default tasksSlice.reducer
