import { useCallback, useEffect, useState } from 'react'

function useWindowSize() {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    prevWidth: 0,
    prevHeight: 0
  })

  const updateSize = useCallback(() => {
    setSize(prev => {
      return {
        width: window.innerWidth,
        height: window.innerHeight,
        prevWidth: prev.width,
        prevHeight: prev.height
      }
    })
  }, [])

  useEffect(() => {
    window.addEventListener('resize', updateSize)

    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [updateSize])

  return size
}

export default useWindowSize
