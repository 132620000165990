import './PlanetStateIndicator.css'

import { useMemo } from 'react'

import activeIcon from '@/assets/planet-active.svg'
import completedIcon from '@/assets/planet-completed.svg'
import disabledIcon from '@/assets/planet-disabled.svg'

const timing = t => {
  const minValue = Math.max(t, 0.5) - 0.5
  return minValue / 0.5
}

function PlanetStateIndicator({ state, completion }) {
  let icon = useMemo(() => {
    switch (state) {
      case 'disabled':
        return disabledIcon
      case 'completed':
        return completedIcon
      default:
        return activeIcon
    }
  }, [state])

  if (!icon) return null

  return (
    <div
      className={`d-flex justify-content-center position-relative indicator`}
      style={{
        scale: `${timing(completion)}`
      }}
    >
      {<img src={icon} width={72} height={72} alt="icon" />}
    </div>
  )
}

export default PlanetStateIndicator
