import cn from 'clsx'
import { useEffect, useState } from 'react'

const QuizSlider = ({ children, animated = true, onFinish }) => {
  const [prevChildren, setPrevChildren] = useState(undefined)
  const [isAnimating, setIsAnimating] = useState(false)

  const duration = 300

  useEffect(() => {
    if (!children || !prevChildren || !animated) {
      setPrevChildren(children)

      if (animated) {
        onFinish && onFinish()
      }

      return
    }

    setIsAnimating(true)

    const timer = setTimeout(() => {
      setPrevChildren(children)
      setIsAnimating(false)
      onFinish && onFinish()
    }, duration)
    return () => clearTimeout(timer)
  }, [children, prevChildren])

  return (
    <div className="position-relative h-100 w-100">
      <div className={cn('position-absolute h-100 w-100', { 'slider-left': isAnimating })}>{prevChildren}</div>
      {isAnimating && <div className="position-absolute h-100 w-100 slider-right">{children}</div>}
    </div>
  )
}

export default QuizSlider
