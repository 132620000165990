import { createSlice } from '@reduxjs/toolkit'

const initialState = () => {
  return {
    planetIndex: parseFloat(localStorage.getItem('current-planet-index')) || 0,
    xCoordinate: null,
    touchStartTime: 0,
    locked: false,
    animationDeltaCenter: 0,
    selectedPlanet: null,
    topics: null,
    levelIndex: 0
  }
}

export const dragSlice = createSlice({
  name: 'drag',
  initialState,
  reducers: {
    setPlanetIndex: (state, action) => {
      state.planetIndex = action.payload
    },
    setXCoordinate: (state, action) => {
      state.xCoordinate = action.payload
    },
    setTouchStartTime: (state, action) => {
      state.touchStartTime = action.payload
    },
    setLocked: (state, action) => {
      state.locked = action.payload
    },
    setDelta: (state, action) => {
      state.animationDeltaCenter = action.payload
    },
    setLevelIndex: (state, action) => {
      state.levelIndex = action.payload
    },
    setSelectedPlanet: (state, action) => {
      state.selectedPlanet = action.payload
    },
    setTopics: (state, action) => {
      state.topics = action.payload
    },
    setMultiple: (state, action) => {
      Object.assign(state, action.payload)
    }
  },

  selectors: {
    selectPlanetIndex: state => state.planetIndex,
    selectXCoordinate: state => state.xCoordinate,
    selectTouchStartTime: state => state.touchStartTime,
    selectLocked: state => state.locked,
    selectAnimationDeltaCenter: state => state.animationDeltaCenter,
    selectSelectedPlanet: state => state.selectedPlanet,
    selectLevelIndex: state => state.levelIndex,
    selectTopics: state => state.topics
  }
})

export const {
  setPlanetIndex,
  setXCoordinate,
  setTouchStartTime,
  setLocked,
  setDelta,
  setLevelIndex,
  setSelectedPlanet,
  setTopics,
  setMultiple
} = dragSlice.actions

export const {
  selectPlanetIndex,
  selectXCoordinate,
  selectTouchStartTime,
  selectLocked,
  selectAnimationDeltaCenter,
  selectSelectedPlanet,
  selectLevelIndex,
  selectTopics
} = dragSlice.selectors
