import './LevelBottomContainer.css'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import academyIcon from '@/assets/academy.svg'
import arrowIcon from '@/assets/arrow.svg'
import backIcon from '@/assets/back.svg'

function LevelBottomButtons({ buttonTitle, disabled, onClickTopics, onClick }) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className="level-bottom-container px-4">
      <div className="level-bottom-topics" onClick={onClickTopics}>
        <img src={academyIcon} alt="academy" width={24} height={24} />
        <span className="flex-grow-1">{t('levelTopics')}</span>
        <img src={arrowIcon} alt="arrow" width={24} height={24}></img>
      </div>

      <div className="d-flex w-100 flex-row gap-2">
        <button className="btn-action shadows" style={{ width: 54, padding: 0 }}>
          <img
            src={backIcon}
            alt="back"
            width={24}
            height={24}
            style={{ marginTop: 0 }}
            onClick={() => navigate(`/`)}
          />
        </button>
        <button
          className="btn-action shadows white flex-grow-1"
          style={{ width: 54 }}
          onClick={onClick}
          disabled={disabled}
        >
          {buttonTitle}
        </button>
      </div>
    </div>
  )
}

export default LevelBottomButtons
