import './Launcher.css'

import cn from 'clsx'
import { useTranslation } from 'react-i18next'

import spaceship from '/unstoppableship.png'
import spaceLogo from '/unstoppablespace-logo.png'

export function Launcher({ isLoading }) {
  const { t } = useTranslation()

  return (
    <div className={cn('launcher-container', { 'fadeOut-animation': !isLoading })}>
      <img
        src={spaceship}
        alt="Spaceship"
        className="front-image fadeInFromGrayscale-animation"
        width={200}
        height={130}
      />
      <img src={spaceLogo} alt="SpaceLogo" className="back-image fadeIn-animation" width={328} height={194} />
      <div className="blurred-circle-container fadeIn-animation">
        <div className="blurred-circle"></div>
      </div>

      <div className="launcher-text px-5 text-center">
        <h5 className="fadeInUp-animation">{t('learnAndEarn')}</h5>
      </div>
    </div>
  )
}
