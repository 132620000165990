import './PlanetBackground.css'

import cn from 'clsx'
import { useEffect, useState } from 'react'

import playIcon from '@/assets/play_text_icon.svg'
import PlanetGlow from '@/components/planet/PlanetGlow.jsx'
import PlanetStateIndicator from '@/components/planet/PlanetStateIndicator.jsx'
import { finished, image } from '@/helpers/planetHelper.js'
import { BACKGROUND_PLANET_ASPECT } from '@/helpers/uiHelper.js'
import { useVisibilityAnimation } from '@/hooks/useVisibilityAnimation.js'

const DEFAULT_SIZE = 1300
const STATUS_SIZE = 72
const STATUS_MARGIN = BACKGROUND_PLANET_ASPECT * 2 * 1.41

const sizeString = size => `${size.width}px ${size.height}px`

const state = (planet, disabled) => {
  if (!planet.enabled || disabled) {
    return 'disabled'
  } else if (finished(planet)) {
    return 'completed'
  }
  return 'active'
}

const stateStyle = size => {
  return size.width > 2 * STATUS_SIZE
    ? {
        marginLeft: size.width / STATUS_MARGIN,
        marginTop: -size.height / STATUS_MARGIN
      }
    : {}
}

const PlanetBackground = ({
  planet,
  active = true,
  disabled = false,
  size,
  minimizePlaceholder = false,
  showState = true,
  showGlow = false,
  showPlayText = false,
  className,
  children
}) => {
  const [isHighQualityLoaded, setIsHighQualityLoaded] = useState(false)
  const [showHighQuality, setShowHighQuality] = useState(false)
  const stateVisibility = useVisibilityAnimation(showState)

  useEffect(() => {
    const highQualityImg = new Image()
    highQualityImg.src = image(planet.id)
    highQualityImg.onload = () => {
      setIsHighQualityLoaded(true)
    }
  }, [planet])

  useEffect(() => {
    let show = isHighQualityLoaded && active
    setShowHighQuality(show)
  }, [active, isHighQualityLoaded])

  const imageSize = size ?? { width: DEFAULT_SIZE, height: DEFAULT_SIZE }
  const placeholderSize =
    showHighQuality && minimizePlaceholder
      ? { width: imageSize.width * 0.9, height: imageSize.height * 0.9 }
      : imageSize

  return (
    <div className={cn('planet-slider-item', className)}>
      <div className={cn('planet-glow-wrapper', { glow: showGlow })}>
        <PlanetGlow size={imageSize.width} planetId={planet.id} />
      </div>

      <div
        className={cn('planet-slider-image', showHighQuality ? 'out-animation' : 'in-animation')}
        style={{
          backgroundSize: sizeString(placeholderSize),
          backgroundImage: `url(${image(planet.id, true)})`
        }}
      />
      <div
        className={cn('planet-slider-image', showHighQuality ? 'in-animation' : 'out-animation')}
        style={{
          backgroundSize: sizeString(imageSize),
          backgroundImage: `url(${image(planet.id, false)})`
        }}
      >
        {showPlayText && (
          <div className="planet-background-state" style={{}}>
            <img src={playIcon} alt="" className="scale-animation" width={82} />
          </div>
        )}
        {/*completed-disabled indicator*/}

        <div className={cn('planet-background-state', stateVisibility.animationClass)} style={stateStyle(imageSize)}>
          <PlanetStateIndicator completion={showHighQuality && showState ? 1 : 0} state={state(planet, disabled)} />
        </div>

        {/*child components*/}
        {children && <div className="planet-background-content">{children}</div>}
      </div>
    </div>
  )
}

export default PlanetBackground
