import { t } from 'i18next'

import arrowRight from '@/assets/arrow_right_16.svg'
import bannerStar from '@/assets/banner_star.svg'
import checkmark from '@/assets/checkmark_white_16.svg'
import number500 from '@/assets/number_5000.png'
import InstallWalletTimer from '@/features/tasks/InstallWalletTimer'
import useTask from '@/hooks/useTask.js'

import walletImage from '../../../public/wallet.png'

function InstallWalletTask({ task }) {
  const { loading, taskStartFrom, onClick, onComplete } = useTask(task)
  const installTextIcon = task.completed ? checkmark : arrowRight

  return (
    <div className="position-relative overflow-hidden pt-3">
      <InstallWalletTimer startFrom={taskStartFrom} onComplete={onComplete} />

      <div
        className="rounded-4 bg-cl-dark1 d-flex justify-content-center overflow-hidden"
        style={{
          height: '132px'
        }}
        onClick={onClick}
      >
        <img src={bannerStar} alt="star" className="" />
        <div className="position-absolute top-0 start-0 w-100">
          <div
            className="d-flex"
            style={{
              paddingLeft: '10%',
              paddingTop: '25px',
              width: '60%'
            }}
          >
            <div className="text-warning base-font d-flex flex-wrap flex-column align-items-center">
              <div className="h6">{t('get')}</div>
              <img src={number500} alt="5000" width={128} />
              <div className="h6 mt-2">UWT</div>
              <div className="text-white d-flex align-items-center" style={{ fontSize: '10px' }}>
                <span>{t('installWallet')}</span>
                {loading ? (
                  <div className="ms-1">
                    <div
                      className="spinner-border spinner-border-sm"
                      style={{
                        height: '10px',
                        width: '10px',
                        animationDuration: '3s'
                      }}
                    />
                  </div>
                ) : (
                  <img src={installTextIcon} alt="arrow" width={16} className="ms-1" />
                )}
              </div>
            </div>
          </div>
        </div>
        <img src={walletImage} alt="wallet" width="35%" className="position-absolute top-0 end-0 me-4" />
      </div>
    </div>
  )
}

export default InstallWalletTask
