import './Quiz.css'

import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { postAnswer } from '@/api/api.js'
import { selectQuiz, updateQuiz } from '@/features/planet/planetSlice.js'
import QuizLoading from '@/features/planet/quiz/QuizLoading.jsx'
import QuizMessage from '@/features/planet/quiz/QuizMessage.jsx'
import QuizQuestion from '@/features/planet/quiz/QuizQuestion.jsx'
import QuizResult from '@/features/planet/quiz/QuizResult.jsx'
import QuizSlider from '@/features/planet/quiz/QuizSlider.jsx'
import { QuizState } from '@/features/planet/quiz/QuizState.jsx'
import { selectUser, setEnergyFillsAt } from '@/features/user/usersSlice.js'
import { hasThresholdEnergy } from '@/helpers/energyHelper.js'

function Quiz() {
  const { planetId, level } = useParams()

  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const quiz = useSelector(state => selectQuiz(state, planetId, level - 1))
  const user = useSelector(selectUser)

  const [state, setState] = useState(QuizState.LOADING)
  const [question, setQuestion] = useState(undefined)
  const [answers, setAnswers] = useState(undefined)
  const [correctAnswers, setCorrectAnswers] = useState(undefined)
  const [explanation, setExplanation] = useState(undefined)
  const [nextQuestion, setNextQuestion] = useState(undefined)
  const [apiError, setApiError] = useState(undefined)
  const [showAnimatedTransition, setShowAnimatedTransition] = useState(false)

  useEffect(() => {
    if (state !== QuizState.LOADING) {
      return
    }

    if (quiz.stars > 0) {
      setState(QuizState.FINISHED)
      return
    }

    if (!hasThresholdEnergy(user)) {
      setState(QuizState.INSUFFICIENT_GAS)
      return
    }

    getNewQuestion()
  }, [state])

  const getNewQuestion = answers => {
    postAnswer(quiz.id, question?.id, answers && answers.length ? answers : undefined)
      .then(response => {
        dispatch(setEnergyFillsAt(response.energy_fills_at))
        dispatch(updateQuiz({ planetId: planetId, level: level - 1, quiz: response.quiz }))

        if (question) {
          setNextQuestion(convertAnswers(response.nextQuestion))

          if (response.isCorrect) {
            setCorrectAnswers(answers)
          } else {
            setCorrectAnswers(response.correctAnswers)
          }

          setExplanation(response.explanation)

          setState(QuizState.SHOWING_ANSWER)
        } else {
          setQuestion(convertAnswers(response.nextQuestion))
          setState(QuizState.SHOWING_QUESTION)
        }
      })
      .catch(err => {
        console.error(err.message)

        if (err.message.toLowerCase().includes('insufficient energy')) {
          setState(QuizState.INSUFFICIENT_GAS)
        } else {
          setApiError(err.message)
          setState(QuizState.API_ERROR)
        }
      })
  }

  const onSelectAnswers = indices => {
    setAnswers(indices)
    setState(QuizState.SUBMITTING_ANSWER)

    // todo: need to wait at least 1-2 seconds before showing answer (but this time should include network request)
    getNewQuestion(indices)
  }

  const onTimeout = () => {
    onSelectAnswers([])
  }

  const onNext = () => {
    setShowAnimatedTransition(true)

    if (quiz.answers === quiz.questions) {
      setState(QuizState.FINISHED)
    } else if (nextQuestion) {
      setAnswers(undefined)
      setCorrectAnswers(undefined)
      setQuestion(nextQuestion)
      setState(QuizState.SHOWING_QUESTION)
    } else {
      setState(QuizState.INSUFFICIENT_GAS)
    }
  }

  const reload = () => {
    setAnswers(undefined)
    setCorrectAnswers(undefined)
    setQuestion(undefined)
    setState(QuizState.LOADING)
  }

  let content = useMemo(() => {
    if (state === QuizState.LOADING) {
      return <QuizLoading />
    } else if ([QuizState.SHOWING_QUESTION, QuizState.SUBMITTING_ANSWER, QuizState.SHOWING_ANSWER].includes(state)) {
      return (
        <QuizQuestion
          key={question?.id}
          state={state}
          question={question}
          questionIndex={quiz.answers}
          questionCount={quiz.questions}
          answers={answers}
          correctAnswers={correctAnswers}
          explanation={explanation}
          onSelectAnswers={onSelectAnswers}
          onTimeout={onTimeout}
          onNext={onNext}
        />
      )
    } else if (state === QuizState.INSUFFICIENT_GAS) {
      return (
        <QuizMessage
          animation="Warning"
          title={t('quizInsufficientGas')}
          text={t('quizInsufficientGasDescription')}
          bigButtonText={t('goToTasks')}
          onBigButtonClick={() => {
            navigate('/tasks')
          }}
          onExitButtonClick={() => {
            navigate(-1)
          }}
        />
      )
    } else if (state === QuizState.API_ERROR) {
      return (
        <QuizMessage
          animation="SkullAndBones"
          title={t('quizError')}
          text={apiError}
          bigButtonText={t('retry')}
          onBigButtonClick={reload}
        />
      )
    } else if (state === QuizState.FINISHED) {
      return <QuizResult quiz={quiz} onFinish={() => navigate(-1)} onTryAgain={reload} />
    } else {
      return <div />
    }
  }, [state])

  return (
    <div className="vh-100 overflow-auto" style={{ paddingTop: 64 }}>
      <QuizSlider animated={showAnimatedTransition} onFinish={() => setShowAnimatedTransition(false)}>
        {content}
      </QuizSlider>
    </div>
  )
}

function convertAnswers(question) {
  if (!question) return undefined

  let options = question?.options?.map((it, i) => ({ index: i, answer: it }))
  const randomIndexes = [...Array(options.length).keys()].sort(() => Math.random() - 0.5)
  options = options.map((option, i) => ({
    ...option,
    randomIndex: randomIndexes[i]
  }))

  return { ...question, options }
}

export default Quiz
