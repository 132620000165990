import './BottomSheet.css'

import { useTranslation } from 'react-i18next'

import BottomSheet from '@/components/bottom-sheet/BottomSheet.jsx'

const ExplanationBottomSheet = ({ content, layoutZIndex, handleClose }) => {
  const { t } = useTranslation()
  return (
    <BottomSheet title={t('explanation')} layoutZIndex={layoutZIndex + 1} onClose={handleClose} wrapContent>
      <div className="d-flex align-items-center">
        <p className="base-font mx-4 mb-5">{content}</p>
      </div>
    </BottomSheet>
  )
}

export default ExplanationBottomSheet
