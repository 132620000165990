const defaultSymbols = ['', 'K', 'M', 'G', 'T', 'P', 'E']

export function compact(value, decimals = 2) {
  const sign = Math.sign(value) != -1
  value = Math.abs(value)
  let prefix = ''
  if (!sign) {
    prefix = '-' + prefix
  }

  const tier = (Math.log10(value) / 3) | 0
  const result = (value / 1000 ** tier).toLocaleString('en-US', {
    useGrouping: true,
    maximumFractionDigits: decimals
  })

  return prefix + result + defaultSymbols[tier % defaultSymbols.length]
}

export function botLink(referralCode) {
  return `https://t.me/BeUnstoppable_bot/app?startapp=${referralCode}`
}

export function inviteFriendsUrl(referralCode, shareText = '') {
  const baseUrl = botLink(referralCode)
  const fullUrl = shareText ? `${shareText}\n\n${baseUrl}` : baseUrl

  return `https://t.me/share/url?url=${encodeURIComponent(fullUrl)}`
}

export function delay(ms, value) {
  return new Promise(resolve => setTimeout(resolve, ms, value))
}
