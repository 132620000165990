import './BottomSheet.css'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { authenticate } from '@/api/api'
import { socket } from '@/api/socket'
import checkmark from '@/assets/check-active.svg'
import flagEnglish from '@/assets/flag_england.svg'
import flagRussian from '@/assets/flag_russia.svg'
import { fetchList } from '@/features/planet/planetSlice'
import { fetchTasks } from '@/features/tasks/tasksSlice'
import { syncAuthenticatedUser } from '@/features/user/usersSlice'

const LanguageOption = ({ flag, name, selected, onClick }) => (
  <div className="d-flex align-items-center p-3 border-bottom" onClick={onClick} style={{ cursor: 'pointer' }}>
    <img src={flag} alt={`${name} flag`} className="me-3" width={32} height={32} />
    <span className="flex-grow-1">{name}</span>
    {selected && <img src={checkmark} width={24} height={24} alt="" />}
  </div>
)

const LanguageSelectionBottomSheet = ({ show, handleClose, onLanguageChange }) => {
  const handleSelectLanguage = language => {
    onLanguageChange(language)
    handleClose()
  }
  const dispatch = useDispatch()
  const { i18n } = useTranslation()
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language)
  const [isVisible, setIsVisible] = useState(false)
  const [isAnimating, setIsAnimating] = useState(false)

  useEffect(() => {
    if (show) {
      setIsVisible(true)
      setIsAnimating(true)
    } else if (isVisible) {
      setIsAnimating(false)
      setTimeout(() => setIsVisible(false), 300)
    }
  }, [show])

  const switchLanguageCode = async lang => {
    try {
      const { jwt, user } = await authenticate(window.Telegram.WebApp.initData, null, null, lang)
      dispatch(syncAuthenticatedUser(user))
      dispatch(fetchList())
      dispatch(fetchTasks())

      localStorage.setItem('jwt', jwt)
      socket.disconnect()
      socket.connect(jwt)
    } catch (e) {
      console.log(e)
    }
  }

  const handleApply = async lang => {
    await switchLanguageCode(lang)
    handleSelectLanguage(lang)
  }

  const languages = [
    { key: 'en', name: 'English', flag: flagEnglish },
    { key: 'ru', name: 'Русский', flag: flagRussian }
  ]

  return (
    <div className={`bottom-sheet-backdrop ${isVisible ? 'show' : ''}`} onClick={handleClose}>
      <div
        className={`bottom-sheet-modal px-3 bg-cl-dark2 ${isAnimating ? 'animate' : ''}`}
        onClick={e => e.stopPropagation()}
      >
        <div className="container position-fixed top start-0 end-0 text-light mt-3">
          <div className="rounded-4 bg-cl-dark1">
            {languages.map(lang => (
              <LanguageOption
                key={lang.name}
                flag={lang.flag}
                name={lang.name}
                selected={selectedLanguage === lang.key}
                onClick={() => {
                  setSelectedLanguage(lang.key)
                  handleApply(lang.key)
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LanguageSelectionBottomSheet
