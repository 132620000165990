export const STATUS = {
  empty: { name: 'empty', duration: 0 },
  initialGrowPlanet: { name: 'initial-grow-planet', duration: 300 },
  selectPlanet: { name: 'select-planet', duration: 0 },
  growToLevel: { name: 'grow-to-level', duration: 600 },
  reduceToPlanet: { name: 'reduce-to-planet', duration: 600 },
  showLevels: { name: 'show-levels', duration: 600 },
  hideLevels: { name: 'hide-levels', duration: 350 },
  selectLevels: { name: 'select-levels', duration: 0 },
  landingShip: { name: 'landing-ship', duration: 600 },
  growToBackground: { name: 'grow-to-background', duration: 600 },
  reduceToLevels: { name: 'reduce-to-levels', duration: 600 },
  background: { name: 'background', duration: 0 }
}

export const equals = (prev, next) => prev.name === next.name
const initialWithoutSides = { ...STATUS.initialGrowPlanet, showSides: false }

export const levelStatuses = [
  STATUS.growToLevel,
  STATUS.showLevels,
  STATUS.selectLevels,
  STATUS.hideLevels,
  STATUS.landingShip,
  STATUS.reduceToLevels
]

export function animationLine(previous, current) {
  if (current === previous) {
    // set actual status when go back from other bottom page
    if (current === 1) {
      return [STATUS.initialGrowPlanet, STATUS.selectPlanet]
    } else if (current === 2) {
      return [STATUS.selectPlanet]
    } else if (current === 3) {
      return [STATUS.selectLevels]
    } else if (current === 4) {
      return [STATUS.background]
    }
  }
  if (current === 2) {
    // go to selectPlanets scene
    if (previous === 1) {
      //from loading
      return [STATUS.initialGrowPlanet, STATUS.selectPlanet]
    } else if (previous === 3) {
      //form levels screen (hiding levels, then change planet
      return [STATUS.hideLevels, STATUS.reduceToPlanet, STATUS.selectPlanet]
    } else if (previous === 4) {
      //from background screen to select planet
      return [STATUS.initialGrowPlanet, STATUS.selectPlanet]
    }
  } else if (current === 3) {
    // go to selectLevels
    if (previous === 1) {
      //from loading
      return [initialWithoutSides, STATUS.growToLevel, STATUS.showLevels, STATUS.selectLevels]
    } else if (previous === 2) {
      //after select planet
      return [STATUS.growToLevel, STATUS.showLevels, STATUS.selectLevels]
    } else if (previous === 4) {
      //from background
      return [STATUS.reduceToLevels, STATUS.showLevels, STATUS.selectLevels]
    }
  } else if (current === 4) {
    if (previous === 1) {
      //from loading
      return [initialWithoutSides, STATUS.growToBackground, STATUS.background]
    } else if (previous === 2) {
      //after select planet ???
      return [STATUS.background]
    } else if (previous === 3) {
      // after select level
      return [STATUS.growToBackground, STATUS.background]
    }
  }

  return [STATUS.empty]
}

export const currentLevelClass = i => {
  switch (i) {
    case 0:
      return 'planet-current-level'
    default:
      return 'opacity-0'
  }
}

export const backgroundClass = i => {
  switch (i) {
    case 0:
      return 'planet-current-background'
    default:
      return 'opacity-0'
  }
}

export const planetsClass = i => {
  switch (i) {
    case 0:
      return 'planet-slider-current'
    case -1:
      return 'planet-slider-prev'
    case 1:
      return 'planet-slider-next'
    default:
      return 'opacity-0'
  }
}

export const initialAnimationClass = (i, show) => {
  switch (i) {
    case 0:
      return 'planet-slider-current-initial'
    case -1:
      return show ? 'planet-slider-prev-initial' : 'opacity-0'
    case 1:
      return show ? 'planet-slider-next-initial' : 'opacity-0'
    default:
      return 'opacity-0'
  }
}

export function classNameByStatus(status, i) {
  const current = status[0]
  if (!current) return ''

  if (equals(current, STATUS.initialGrowPlanet)) {
    return initialAnimationClass(i, current.showSides ?? true)
  }

  const planetStatuses = [STATUS.selectPlanet, STATUS.reduceToPlanet]
  if (planetStatuses.map(it => it.name).includes(current.name)) {
    const transition = STATUS.reduceToPlanet.name === current.name ? ' reduce-transition' : ''
    return planetsClass(i) + transition
  }

  if (levelStatuses.map(it => it.name).includes(current.name)) {
    return currentLevelClass(i)
  }

  const backgroundStatuses = [STATUS.growToBackground, STATUS.background]
  if (backgroundStatuses.map(it => it.name).includes(current.name)) {
    return backgroundClass(i)
  }

  return 'opacity-0'
}
