import arrowIcon from '@/assets/arrow.svg'

export function SettingItem({ icon, title, onClick }) {
  return (
    <div className="d-flex justify-content-between py-3 px-3" onClick={onClick}>
      <div className="d-flex flex-row align-items-center">
        <img src={icon} alt="" width={24} height={24} className="me-3" />
        <div className="fs-6 fw-bold">{title}</div>
      </div>
      <img src={arrowIcon} alt="" width={24} height={24} />
    </div>
  )
}
