import './Quiz.css'

import cn from 'clsx'

function QuizResultBar({ title, placeholder, value, progress, color }) {
  return (
    <div
      className="position-relative overflow-hidden mx-3 bg-white-20 rounded-32px fs-7 fw-bold text-black"
      style={{ height: 50 }}
    >
      <div
        className={`position-absolute h-100 bg-${color} progress-animation`}
        style={{ width: progress ? `${progress * 100}%` : '0%' }}
      />
      <div className="position-absolute w-100 h-100 d-flex px-4 align-items-center justify-content-between">
        <div className="">{title}</div>
        <div className={cn({ bounce: value })}>{value ?? placeholder}</div>
      </div>
    </div>
  )
}

export default QuizResultBar
