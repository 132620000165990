import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { open } from '@/features/tasks/tasksHelper.js'
import { alwaysClickableIds } from '@/features/tasks/tasksSlice.js'

const useTask = task => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const completeTime = task.completeTime

  const onClick = () => {
    if (alwaysClickableIds.has(task.id) && task.completed) {
      dispatch(open(task.id))
      return
    }

    if (task.completed || loading) return

    dispatch(open(task.id))
    localStorage.setItem(`task-${task.id}`, `${Date.now()}`)

    if (completeTime) {
      setLoading(true)
    }
  }

  const points = `+${task.points} UWT`

  const taskStartFrom = Number(localStorage.getItem(`task-${task.id}`))

  const onComplete = () => {
    localStorage.removeItem(`task-${task.id}`)
    setLoading(false)
  }

  if (taskStartFrom !== 0) {
    if (!loading) {
      setLoading(true)
    }
  }

  return { loading, taskStartFrom, points, onClick, onComplete }
}

export default useTask
