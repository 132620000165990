import './Home.css'

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import EnergyProgressBar from '@/components/energy/EnergyProgressBar.jsx'
import ValueBar from '@/components/value-bar/ValueBar.jsx'
import { getEnergy } from '@/helpers/energyHelper.js'
import useEnergyRefillTimer from '@/hooks/useEnergyRefillTimer.js'

export const TOP_BAR_HEIGHT = 48

function HomeTopBar({ user }) {
  const navigate = useNavigate()
  const [energy, setEnergy] = useState(getEnergy(user))

  useEnergyRefillTimer({ setEnergy })

  const pointsString = `${user?.points || 0} UWT`

  return (
    <div className="home-top-bar-container z-1">
      <ValueBar className="home-top-bar-points">
        <div
          className="w-100 h-100 d-flex align-items-center justify-content-center"
          onClick={() => navigate(`/profile/${user.id}`)}
        >
          <h6 className="text-white fw-bold mb-0">{pointsString}</h6>
        </div>
      </ValueBar>

      <ValueBar className="home-top-bar-gas">
        <EnergyProgressBar energy={energy} maxEnergy={user?.energy_max} />
      </ValueBar>
    </div>
  )
}

export default HomeTopBar
