import i18n from 'i18next'

import { getOAuthJWTToken, twitterAuthorizationLink, youtubeAuthorizationLink } from '@/api/api.js'
import { socket } from '@/api/socket'
import { selectTask, Tasks } from '@/features/tasks/tasksSlice.js'
import { selectUser } from '@/features/user/usersSlice'
import { inviteFriendsUrl } from '@/helpers/helper.js'

export const open = taskId => async (dispatch, getState) => {
  const state = getState()
  const user = selectUser(state)
  const task = selectTask(state, taskId)

  const shareText = i18n.t('joinText')
  const url = (import.meta.env.VITE_SOCKET_URL || 'https://be.unstoppable.money') + i18n.t('shareUrl')
  if (!user || !task) {
    return
  }

  switch (task.id) {
    case Tasks.welcomeReward:
      socket.emit('task-open', { taskId: taskId, userId: user.id })
      break

    case Tasks.joinCommunity:
      socket.emit('task-open', { taskId: taskId, userId: user.id })
      window.Telegram.WebApp.openTelegramLink('https://t.me/unstoppable_announcements')
      break

    case Tasks.joinCommunityRu:
      socket.emit('task-open', { taskId: taskId, userId: user.id })
      window.Telegram.WebApp.openTelegramLink('https://t.me/unstoppable_announcements_ru')
      break

    case Tasks.shareStory:
      socket.emit('task-open', { taskId: taskId, userId: user.id })

      window.Telegram.WebApp.shareToStory(`${url}?${Date.now()}`, {
        text: `${i18n.t('joinCommunity')} https://t.me/BeUnstoppable_bot/app?startapp=${encodeURIComponent(user.referral_code)}`
      })
      break

    case Tasks.installWallet:
      window.Telegram.WebApp.openLink(
        `https://unstoppable.money/referral?userId=${user.id}&referralCode=${user.referral_code}`
      )
      break

    case Tasks.followCommunityX:
      socket.emit('task-open', { taskId: taskId, userId: user.id })
      window.Telegram.WebApp.openLink('https://x.com/unstoppablebyhs')
      break

    case Tasks.followCommunityXRU:
      socket.emit('task-open', { taskId: taskId, userId: user.id })
      window.Telegram.WebApp.openLink('https://x.com/unstoppable_edu')
      break

    case Tasks.followCommunityXVerified:
    case Tasks.retweetOnX:
      if (user.twitter_authorized && task.completed) {
        window.Telegram.WebApp.openLink(
          user.language_code === 'ru' ? 'https://x.com/unstoppable_edu' : 'https://x.com/unstoppablebyhs'
        )
        return
      }

      getOAuthJWTToken(taskId, user.language_code).then(response => {
        return window.Telegram.WebApp.openLink(twitterAuthorizationLink(response.jwt))
      })
      break

    case Tasks.subscribeUwYoutube:
      socket.emit('task-open', { taskId: taskId, userId: user.id })
      window.Telegram.WebApp.openLink('https://www.youtube.com/@unstoppablewallet')
      break

    case Tasks.subscribeUwYoutubeVerified:
      if (task.completed) {
        window.Telegram.WebApp.openLink('https://www.youtube.com/@unstoppablewallet')
        return
      }

      getOAuthJWTToken(taskId, user.language_code).then(response => {
        return window.Telegram.WebApp.openLink(youtubeAuthorizationLink(response.jwt))
      })
      break

    case 'invite-friend':
    case 'invite-5-friend':
    case 'invite-10-friend':
    case 'invite-25-friend':
      window.Telegram.WebApp.openTelegramLink(inviteFriendsUrl(user.referral_code, shareText))
      break

    case Tasks.boostCommunityChannel:
      socket.emit('task-open', { taskId: taskId, userId: user.id })
      window.Telegram.WebApp.openTelegramLink('https://t.me/boost/unstoppable_announcements')
      break
  }
}
