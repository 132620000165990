import { useEffect } from 'react'

export function ScrollToTop() {
  useEffect(() => {
    const container = document.getElementById('app')
    if (container) {
      container.scrollTop = 0
    }
  }, [])
}
