import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import announcementsIcon from '@/assets/announcements-icon.svg'
import copyIcon from '@/assets/copy-icon.svg'
import globeIcon from '@/assets/globe-icon.svg'
import messageIcon from '@/assets/message-icon.svg'
import successIcon from '@/assets/success.svg'
import supportIcon from '@/assets/support-icon.svg'
import uwIcon from '@/assets/uw-icon.svg'
import LanguageSelectBottomSheet from '@/components/bottom-sheet/LanguageSelectBottomSheet'
import { SettingItem } from '@/features/settings/SettingItem'
import { selectUser } from '@/features/user/usersSlice'
import { botLink, inviteFriendsUrl } from '@/helpers/helper.js'

function Settings() {
  const { t, i18n } = useTranslation()
  const [copied, setCopied] = useState(false)
  const user = useSelector(selectUser)
  const iconForCopy = copied ? successIcon : copyIcon
  const [showLanguageSelect, setShowLanguageSelect] = useState(false)

  const onClickInvite = () => {
    window.Telegram.WebApp.openTelegramLink(inviteFriendsUrl(user?.referral_code, t('joinText')))
  }

  const handleLanguageChange = useCallback(
    async newLanguage => {
      await i18n.changeLanguage(newLanguage)
      setShowLanguageSelect(false)
    },
    [i18n]
  )

  const handleClose = () => {
    setShowLanguageSelect(false)
  }

  const onCopyClick = () => {
    navigator.clipboard
      .writeText(botLink(user?.referral_code))
      .then(() => {
        setCopied(true)
        setTimeout(() => {
          setCopied(false)
        }, 2000)
      })
      .catch(err => {
        console.error('Failed to copy: ', err)
      })
  }

  const handleAppButtonClick = () => {
    const userAgent = navigator.userAgent || window.opera

    if (/android/i.test(userAgent)) {
      window.open('https://play.google.com/store/search?q=unstoppable+wallet&c=apps', '_blank')
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.open('https://apps.apple.com/us/app/unstoppable-crypto-wallet/id1447619907', '_blank')
    } else {
      window.Telegram.WebApp.openLink('https://unstoppable.money/')
    }
  }

  return (
    <div className="stars-container px-3 pb-4 w-100 align-items-center">
      <div className="h3 text-center" style={{ marginTop: 32, marginBottom: 32 }}>
        {t('settings')}
      </div>
      <div className="bg-cl-dark1 rounded-4">
        <SettingItem
          title={t('support')}
          icon={supportIcon}
          onClick={() => window.Telegram.WebApp.openTelegramLink('https://t.me/beunstoppable_support')}
        />
        <div className="border-bottom" />
        <SettingItem
          title={t('faq')}
          icon={messageIcon}
          onClick={() => window.Telegram.WebApp.openLink(t('faqUrl'), { try_instant_view: true })}
        />
        <div className="border-bottom" />
        <SettingItem title={t('language')} icon={globeIcon} onClick={() => setShowLanguageSelect(true)} />
        <div className="border-bottom" />
        <SettingItem
          title={t('beUnstoppableChannel')}
          icon={announcementsIcon}
          onClick={() => window.Telegram.WebApp.openTelegramLink(t('beUnstoppableUrl'))}
        />
        <div className="border-bottom" />
        <SettingItem title={t('unstoppableWallet')} icon={uwIcon} onClick={handleAppButtonClick} />
      </div>

      <div className="bg-cl-dark1 rounded-24px p-3 text-black mt-4">
        <div className="fw-bold text-white">{t('yourReferralLink')}</div>
        <div className="d-flex flex-row mt-3">
          <button className="btn-action flex-grow-1 text-black me-2" onClick={onClickInvite}>
            {t('share')}
          </button>
          <button className="btn-action-circle rounded-circle flex-shrink-0" onClick={onCopyClick}>
            <img src={iconForCopy} alt="" height={24} width={24} />
          </button>
        </div>
      </div>
      <LanguageSelectBottomSheet
        show={showLanguageSelect}
        handleClose={handleClose}
        onLanguageChange={handleLanguageChange}
      />
    </div>
  )
}

export default Settings
