import checkIcon from '@/assets/check_green_16.svg'
import InstallWalletTimer from '@/features/tasks/InstallWalletTimer'
import StubTaskTimer from '@/features/tasks/StubTaskTimer'
import { Tasks } from '@/features/tasks/tasksSlice'
import useTask from '@/hooks/useTask.js'

function ButtonLayout({ completed, points }) {
  return (
    <div>
      {completed ? (
        <div
          className="text-success d-flex align-items-center gap-1"
          style={{
            minWidth: 'fit-content',
            whiteSpace: 'nowrap'
          }}
        >
          <img
            src={checkIcon}
            alt="check"
            width={16}
            style={{
              paddingTop: '2px'
            }}
          />
          {points.replace(/^\+/, '')}
        </div>
      ) : (
        <div
          className="btn btn-secondary rounded-3 fw-semibold"
          style={{
            border: '1px solid rgba(248, 249, 250, 0.2)',
            minWidth: 'fit-content',
            whiteSpace: 'nowrap'
          }}
        >
          <small>{points}</small>
        </div>
      )}
    </div>
  )
}

function Task({ task, onTaskComplete }) {
  const { loading, taskStartFrom, points, onClick, onComplete } = useTask(task)

  const handleComplete = () => {
    onComplete()

    if (task.completed) {
      onTaskComplete({
        points: points
      })
    }
  }

  return (
    <div
      className="d-flex justify-content-between align-items-center px-3"
      onClick={onClick}
      style={{
        minHeight: '65px'
      }}
    >
      {task.id === Tasks.installWallet ? (
        <InstallWalletTimer startFrom={taskStartFrom} onComplete={handleComplete} />
      ) : (
        <StubTaskTimer startFrom={taskStartFrom} interval={task.completeTime} onComplete={handleComplete} />
      )}
      <div className={`my-2 me-3 ${!loading && task.completed ? 'text-white-50' : 'text-white'}`}>{task.name}</div>
      {loading ? (
        <div className="px-3">
          <span
            className="spinner-border spinner-border-sm px-2"
            style={{
              width: '24px',
              height: '24px',
              animationDuration: '3s'
            }}
          />
        </div>
      ) : (
        <ButtonLayout completed={task.completed} points={points} />
      )}
    </div>
  )
}

export default Task
