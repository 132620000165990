export function ProfileItem({ icon, title, subtitle = '', value }) {
  return (
    <div className="d-flex justify-content-between py-3 px-3">
      <div className="d-flex flex-row align-items-center">
        <img src={icon} alt="" width={24} height={24} className="me-3" />
        <div className="d-flex flex-column">
          <div>{title}</div>
          {subtitle && <div className="base-font text-white-50 small">{subtitle}</div>}
        </div>
      </div>
      <div className="d-flex align-items-center">{value}</div>
    </div>
  )
}
