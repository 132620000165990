import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import cupIcon from '@/assets/cup.png'
import Animation from '@/components/animation/Animation'
import RankList from '@/features/home/RankList'
import UserListItem from '@/features/user/UserListItem'
import { fetchStats, selectStats, selectStatsStatus, selectUser, selectUserStatus } from '@/features/user/usersSlice'

export function Rank() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector(selectUser)
  const userStatus = useSelector(selectUserStatus)
  const stats = useSelector(selectStats)
  const statsStatus = useSelector(selectStatsStatus)
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(fetchStats())
  }, [dispatch])

  const loadingStatuses = ['idle', 'fetching']
  const loading = loadingStatuses.includes(statsStatus) || loadingStatuses.includes(userStatus)

  const hasData = user && stats?.users && stats.users.length > 0
  const showLoading = !hasData && loading

  return (
    <div className="stars-container d-flex flex-column overflow-hidden mt-4 mb-3 gap-3 mb-1 px-3">
      {/*Header*/}
      <div className="d-flex flex-column w-100 align-items-center gap-2">
        <Animation
          className="s128"
          name={'Cup'}
          placeholder={<img src={cupIcon} alt="cup" width={128} height={128} />}
        />
        <h3 className="mb-0">{t('leaderboard')}</h3>
      </div>
      {/*User*/}
      <div className="bg-cl-dark rounded-4" onClick={() => navigate(`/profile/${user?.id}`)}>
        <UserListItem name={user?.name} prefix="#" value={user?.rank} subtitle={user?.points} avatar={user?.avatar} />
      </div>
      {/*Rating*/}
      <RankList loading={showLoading} list={stats?.users} />
    </div>
  )
}

export default Rank
