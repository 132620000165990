import './Home.css'

import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useParams } from 'react-router-dom'

import PartnerRewardAlert from '@/components/alert/PartnerRewardAlert.jsx'
import DragSlider from '@/components/drag-slider/DragSlider.jsx'
import HomeTopBar, { TOP_BAR_HEIGHT } from '@/features/home/HomeTopBar'
import { fetchList, selectList, selectListStatus } from '@/features/planet/planetSlice'
import { fetchTasks, selectList as selectTaskList } from '@/features/tasks/tasksSlice'
import { selectIsLoggedIn, selectUser, selectUserStatus } from '@/features/user/usersSlice'

const contentInsets = { top: TOP_BAR_HEIGHT + 24 + 76 + 32, left: 48, bottom: 76 + 24 + 88 + 8, right: 48 }

export function Home() {
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const userStatus = useSelector(selectUserStatus)
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const list = useSelector(selectList)
  const listStatus = useSelector(selectListStatus)
  const taskList = useSelector(selectTaskList)

  const { planetId } = useParams()
  const [showPartnerReward, setShowPartnerReward] = useState(false)

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchList())
      dispatch(fetchTasks())
      if (user.has_welcome_reward) {
        setShowPartnerReward(true)
      }
    }
  }, [dispatch, isLoggedIn])

  const [planetIndex, setPlanetIndex] = useState(parseFloat(localStorage.getItem('current-planet-index')) || 0)
  const onChangeIndex = useCallback(
    index => {
      setPlanetIndex(index)
    },
    [planetIndex, list]
  )

  const loadingStatuses = ['idle', 'fetching']
  const loading = loadingStatuses.includes(listStatus) || loadingStatuses.includes(userStatus)
  const hasData = user && taskList && list && list.length > 0
  const showLoading = !hasData && loading

  return (
    <div className="position-relative w-100 h-100 d-flex flex-column align-items-center">
      {/*Show partners bonus*/}
      {showPartnerReward && planetId && <PartnerRewardAlert onClose={() => setShowPartnerReward(false)} />}
      {/*Header block*/}
      <div className="position-absolute start-0 w-100 z-3">
        <HomeTopBar user={user} />
        {/*<div className="position-relative energy-position">*/}
        {/*  <EnergyProgressBar energy={energy} maxEnergy={user?.energy_max} />*/}
        {/*</div>*/}
      </div>

      <DragSlider
        loading={showLoading}
        planets={list}
        contentInsets={contentInsets}
        onChangeIndex={i => onChangeIndex(i)}
      >
        <Outlet />
      </DragSlider>
    </div>
  )
}

export default Home
