const HOUR_IN_SECONDS = 60 * 60
const _refillHourly = 40
const _maxEnergy = 960
const _questionCost = 80

const energies = user => {
  return { max: user.energy_max || _maxEnergy, refill: user.energy_refill_hourly || _refillHourly }
}

export function getEnergy(user) {
  if (!user) return _maxEnergy

  const { max, refill } = energies(user)

  const now = Date.now() / 1000
  const hoursUntilFullEnergy = Math.max(Math.ceil((user.energy_fills_at - now) / HOUR_IN_SECONDS), 0)
  const energy = max - hoursUntilFullEnergy * refill

  return Math.max(Math.min(energy, max), 0)
}

export function hasThresholdEnergy(user) {
  return getEnergy(user) >= _questionCost
}

export function energyPercent(user) {
  const energy = getEnergy(user)
  if (!user) return '100%'

  const { max } = energies(user)

  return `${Math.floor((energy / max) * 100)}%`
}

export function nextEnergyUpdate(user) {
  if (!user) return undefined

  const now = Date.now() / 1000
  if (user.energy_fills_at <= now) return undefined
  const distance = user.energy_fills_at - now
  return (distance % HOUR_IN_SECONDS) * 1000
}
