import './i18n'
import './main.scss'

import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import { Initialize } from '@/components/Initialize'

import { Routes } from '@/features/Routes'
import { SentryAPI } from '@/helpers/sentry'
import { store } from './store'

window.Telegram.WebApp.expand()
window.Telegram.WebApp.setHeaderColor('#000000')
window.Telegram.WebApp.setBackgroundColor('#000000')
window.Telegram.WebApp.ready()
window.Telegram.WebApp.disableVerticalSwipes()
window.Telegram.WebApp.disableClosingConfirmation()

SentryAPI.initialize()

ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
  <Initialize>
    <Provider store={store}>
      <Routes />
    </Provider>
  </Initialize>
  // </React.StrictMode>,
)
