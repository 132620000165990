import { useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { selectUser } from '@/features/user/usersSlice'
import { getEnergy, nextEnergyUpdate } from '@/helpers/energyHelper.js'

const useEnergyRefillTimer = ({ setEnergy }) => {
  const user = useSelector(selectUser)
  const timerRef = useRef(null)

  const clearCurrentTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
  }

  const setNextTimer = useCallback(() => {
    const nextTriggerIn = nextEnergyUpdate(user)
    clearCurrentTimer()

    timerRef.current = setTimeout(() => {
      setEnergy(getEnergy(user))
      setNextTimer()
    }, nextTriggerIn)
  }, [user?.energy_fills_at])

  useEffect(() => {
    setEnergy(getEnergy(user))
    if (user?.energy_fills_at) {
      setNextTimer()
    }

    return clearCurrentTimer
  }, [user?.energy_fills_at])

  return null
}

export default useEnergyRefillTimer
