import { useTranslation } from 'react-i18next'

import closeIcon from '@/assets/close-popup-icon.svg'
import AlertBase from '@/components/alert/AlertBase.jsx'

function PlanetLockedAlert({ previousPlanet, thisPlanet, onClose }) {
  const { t } = useTranslation()

  return (
    <AlertBase onClose={onClose}>
      <div>
        <div className="d-flex align-items-center">
          <div style={{ width: '24px' }}></div>
          <span className="h5 text-center flex-grow-1 mx-1" style={{ marginBottom: '0' }}>
            {t('planetLocked')}
          </span>
          <img onClick={onClose} src={closeIcon} style={{ width: '24px', height: '24px' }} className="text-gray-500" />
        </div>
        <p className="mt-4 base-font text-center">
          {t('planetLockedDescription', { previousPlanet: previousPlanet, thisPlanet: thisPlanet })}
        </p>
      </div>
    </AlertBase>
  )
}

export default PlanetLockedAlert
