const glowColor = planetId => {
  switch (planetId) {
    case 'baselion':
      return '#1681FF'
    case 'satoshira':
      return '#965300'
    case 'etheria':
      return '#88009A'
  }
}

function GlowCircle({ size, planetId }) {
  const fullSize = 2 * size
  const blurSize = size / 10

  const circleStyle = {
    width: size,
    height: size,
    borderRadius: '50%',
    backgroundColor: glowColor(planetId),
    opacity: 0.6,
    filter: `blur(${blurSize}px)`,
    boxShadow: `0 0 ${blurSize}px ${glowColor(planetId)}`
  }

  return (
    <div
      className="position-relative d-flex justify-content-center align-items-center"
      style={{ width: fullSize, height: fullSize }}
    >
      <div style={circleStyle} />
    </div>
  )
}

export default GlowCircle
