import useWindowSize from '@/hooks/useWindowSize.js'

export const mainTopPath = calcX => {
  return {
    id: '4351_18378',
    path: `
      M0 0
      H${calcX(360)}
      V72
      H${calcX(301.255)}
      C${calcX(292.768)} 72 ${calcX(284.629)} 68.6286 ${calcX(278.627)} 62.6274
      L${calcX(273.373)} 57.3726
      C${calcX(267.371)} 51.3714 ${calcX(259.232)} 48 ${calcX(250.745)} 48
      H109.255
      C100.768 48 92.6286 51.3714 86.6274 57.3726
      L81.3726 62.6274
      C75.3714 68.6286 67.2321 72 58.7452 72
      H0
      V0Z
  `,
    startY: -5.625,
    height: 72
  }
}

export const mainBottomPath = calcX => {
  return {
    id: '4351_18367',
    path: `
      M${calcX(317.373)} 9.37258
      C${calcX(311.371)} 3.37142 ${calcX(303.232)} 0 ${calcX(294.745)} 0
      H65.2548
      C56.7679 0 48.6286 3.37142 42.6274 9.37259
      L37.3726 14.6274
      C31.3714 20.6286 23.2321 24 14.7452 24
      H0
      V76
      H${calcX(360)}
      V24
      H${calcX(345.255)}
      C${calcX(336.768)} 24 ${calcX(328.629)} 20.6286 ${calcX(322.627)} 14.6274
      L${calcX(317.373)} 9.37258Z
    `,
    startY: -5.9375,
    height: 76
  }
}

export const quizTopPath = calcX => {
  return {
    id: '4548_266',
    path: `
      M0 0
      H${calcX(360)}
      V48
      H${calcX(346.627)}
      C${calcX(342.384)} 48 ${calcX(338.314)} 46.3143 ${calcX(335.314)} 43.3137
      L${calcX(328.686)} 36.6863
      C${calcX(325.686)} 33.6857 ${calcX(321.616)} 32 ${calcX(317.373)} 32
      H42.6274
      C38.384 32 34.3143 33.6857 31.3137 36.6863
      L24.6863 43.3137
      C21.6857 46.3143 17.616 48 13.3726 48
      H0
      V0Z
  `,
    startY: -3.75,
    height: 48
  }
}

const HeaderFrame = ({ curvePath }) => {
  const originalWidth = 360

  const { width } = useWindowSize()

  const calcX = x => x + width - originalWidth
  const params = curvePath(calcX)

  return (
    <svg
      width={width}
      height={params.height}
      viewBox={`0 0 ${width} ${params.height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d={params.path} fill={`url(#paint0_linear_${params.id})`} />
      <defs>
        <linearGradient
          id={`paint0_linear_${params.id}`}
          x1={calcX(156.94)}
          y1={params.startY}
          x2={calcX(156.94)}
          y2={params.height}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E4E4E4" />
          <stop offset="0.255021" stopColor="white" />
          <stop offset="0.635021" stopColor="#A3A3A3" />
          <stop offset="1" stopColor="#D4D4D4" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default HeaderFrame
