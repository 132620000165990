import { useEffect, useState } from 'react'

import { Launcher } from '@/components/launcher/Launcher.jsx'
import { appLoader } from '@/features/app/appLoader'

export function Initialize({ children }) {
  const [isInitialized, setIsInitialized] = useState(false)
  const [isTimePast, setIsTimePast] = useState(false)
  const [isAnimating, setIsAnimating] = useState(true)

  useEffect(() => {
    async function initialize() {
      try {
        await appLoader()
        setIsInitialized(true)
      } catch (error) {
        console.error('initialize error', error)
      }
    }

    setTimeout(() => {
      setIsTimePast(true)

      setTimeout(() => {
        setIsAnimating(false)
      }, 1000) // cross fade animation time
    }, 1500)

    initialize().catch(console.error)
  }, [])

  const isLoading = !isInitialized || !isTimePast

  return (
    <>
      {(isLoading || isAnimating) && <Launcher isLoading={isLoading} />}
      {!isLoading && <div className="App-content">{children}</div>}
    </>
  )
}
