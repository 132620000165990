import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export function BackButtonHandler() {
  const location = useLocation()
  const navigate = useNavigate()
  const [history, setHistory] = useState([])

  useEffect(() => {
    setHistory(prev => [...prev, location.pathname])
  }, [location])

  useEffect(() => {
    const handleBackButton = () => {
      if (history.length > 1) {
        setHistory(prev => prev.slice(0, -1))
        navigate(-1)
      } else {
        navigate('/')
      }
    }

    window.Telegram.WebApp.BackButton.onClick(handleBackButton)

    return () => {
      window.Telegram.WebApp.BackButton.offClick(handleBackButton)
    }
  }, [navigate, history])

  useEffect(() => {
    if (['/', '/tasks', '/friends', '/rank'].includes(location.pathname)) {
      window.Telegram.WebApp.BackButton.hide()
    } else {
      window.Telegram.WebApp.BackButton.show()
    }
  }, [location.pathname])

  return null
}
