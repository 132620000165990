import i18n from 'i18next'

import { getFriends, getStats } from '@/api/api'
import { SentryAPI } from '@/helpers/sentry'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoggedIn: false,
  user: null,
  userStatus: 'idle', // fetching/fetched/failed
  stats: [],
  statsStatus: 'idle', // fetching/fetched/failed
  friends: [],
  friendsStatus: 'idle' // fetching/fetched/failed
}

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.user = action.payload
      state.isLoggedIn = true
      state.userStatus = 'fetched'
    },
    logoutSuccess: state => {
      state.isLoggedIn = false
      state.user = null
      state.userStatus = 'failed'
    },
    setStatsStatus: (state, action) => {
      state.statsStatus = action.payload
    },
    setStats: (state, action) => {
      state.stats = action.payload
      state.statsStatus = 'fetched'
    },
    setUserStatus: (state, action) => {
      state.userStatus = action.payload
    },
    setWelcomePoints: (state, action) => {
      state.user.points += action.payload
    },
    setPoints: (state, action) => {
      const { payload } = action
      state.user = {
        ...state.user,
        points: payload.points,
        earned_points: payload.earned_points,
        quiz_points: payload.quiz_points,
        referral_points: payload.referral_points,
        earned_points_for_referral: payload.earned_points_for_referral
      }
    },
    setEnergyFillsAt: (state, action) => {
      state.user.energy_fills_at = action.payload
    },
    setFriendsStatus: (state, action) => {
      state.friendsStatus = action.payload
    },
    setHasWelcomeReward: (state, action) => {
      state.user = {
        ...state.user,
        has_welcome_reward: action.payload
      }
    },
    setFriends: (state, action) => {
      state.friends = action.payload
      state.friendsStatus = 'fetched'
    }
  },

  selectors: {
    selectUser: state => state.user,
    selectUserStatus: state => state.userStatus,
    selectStats: state => state.stats,
    selectStatsStatus: state => state.statsStatus,
    selectFriends: state => state.friends,
    selectFriendsStatus: state => state.friendsStatus,
    selectIsLoggedIn: user => user.isLoggedIn
  }
})

export const {
  loginSuccess,
  logoutSuccess,
  setUserStatus,
  setWelcomePoints,
  setPoints,
  setEnergyFillsAt,
  setStats,
  setStatsStatus,
  setFriends,
  setFriendsStatus,
  setHasWelcomeReward
} = usersSlice.actions

export const {
  selectUser,
  selectUserStatus,
  selectStats,
  selectStatsStatus,
  selectFriends,
  selectFriendsStatus,
  selectIsLoggedIn
} = usersSlice.selectors

export const fetchStats = () => async (dispatch, getState) => {
  const status = selectStatsStatus(getState())
  if (status === 'fetching') {
    return
  }

  dispatch(setStatsStatus('fetching'))

  getStats()
    .then(users => {
      dispatch(setStats(users))
    })
    .catch(err => {
      console.log(err)
      dispatch(setStatsStatus('failed'))
    })
}

export const fetchFriends = () => async (dispatch, getState) => {
  const friends = selectFriends(getState())
  if (friends.length) {
    return
  }

  dispatch(setFriendsStatus('fetching'))

  getFriends()
    .then(friends => {
      dispatch(setFriends(friends))
      dispatch(setFriendsStatus('fetched'))
    })
    .catch(err => {
      console.log(err)
      dispatch(setFriendsStatus('failed'))
    })
}

export const syncAuthenticatedUser = user => async dispatch => {
  try {
    i18n.changeLanguage(user.language_code)
    SentryAPI.setUser(user.id, user.username)
    dispatch(loginSuccess(user))
  } catch (error) {
    console.error('Error during authenticated user sync:', error)
  }
}
