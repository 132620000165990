import './RoundedProgressBar.css'

import { useEffect, useState } from 'react'

const RoundedProgressBar = ({ progress, total, colors }) => {
  const [mounted, setMounted] = useState(false)
  const [current, setCurrent] = useState(0)
  const percentage = (progress / total) * 100

  const [filledColor, remainingColor] = colors

  useEffect(() => {
    requestAnimationFrame(() => {
      setMounted(true)
    })
  }, [])

  useEffect(() => {
    if (mounted) {
      setCurrent(percentage)
    }
  }, [percentage, mounted])

  return (
    <div className="rounded-progress-bar">
      <div className={`rounded-progress-bar full ${remainingColor}`} />
      <div
        className={`rounded-progress-bar filled ${filledColor}`}
        style={{
          width: `${current}%`
        }}
      />
    </div>
  )
}

export default RoundedProgressBar
