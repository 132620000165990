import './Loader.css'

import LoaderIcon from '/unstoppableship.png'

export function Loader() {
  return (
    <div className="d-flex align-items-center justify-content-center position-absolute start-0 end-0 h-100 w-100 bg-transparent">
      <img
        src={LoaderIcon}
        alt="LoadingSpaceship"
        className="loader-img"
        width={200}
        height={130}
        aria-label="Loading"
      />
    </div>
  )
}

export default Loader
