import './Animation.css'

import Lottie from 'lottie-react'
import { useEffect, useState } from 'react'

const animations = import.meta.glob('/src/assets/*.json')

const Animation = ({ name, placeholder, loop = true, ...props }) => {
  const [animationData, setAnimationData] = useState(null)

  useEffect(() => {
    const loadAnimation = async () => {
      const animationPath = `/src/assets/${name}.json`
      if (animations[animationPath]) {
        const loadedAnimation = await animations[animationPath]()
        setAnimationData(loadedAnimation.default)
      } else {
        console.error(`Animation file not found: ${animationPath}`)
      }
    }

    loadAnimation()
  }, [name])

  if (!animationData) {
    return placeholder || <div {...props} />
  }

  return <Lottie animationData={animationData} {...props} loop={loop} />
}

export default Animation
