import './EnergyProgressBar.css'

import { interpolate } from '@/helpers/colorHelper.js'

const GradientBar = ({ totalWidth, visibleWidth, oneColor = true }) => {
  const colors = [
    { color: '#CF0000', position: 0 },
    { color: '#CACF00', position: 0.5 },
    { color: '#05C46B', position: 1 }
  ]

  const gradientString = `linear-gradient(90deg,
    #FF0000 0px,
    #FAFF00 ${totalWidth * 0.5}px,
    #14FF00 ${totalWidth}px
  )`

  const background = oneColor ? interpolate(colors, visibleWidth / totalWidth) : gradientString

  return (
    <div
      className="rounded-4 energy-blur overflow-hidden h-100"
      style={{
        width: `${visibleWidth}px`,
        background
      }}
    ></div>
  )
}

export default GradientBar
