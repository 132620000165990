import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ExplanationBottomSheet from '@/components/bottom-sheet/ExplanationBottomSheet.jsx'
import { FilledAcademyIcon } from '@/components/Icon.jsx'

function QuizButtons({ explanation, onNext }) {
  const { t } = useTranslation()

  const [showModal, setShowModal] = useState(false)

  return (
    <div className="fadeIn-animation">
      <div className="position-fixed bottom-0 d-flex w-100 gap-2 p-3 mb-3 z-2">
        <button className="btn-action-circle rounded-circle flex-shrink-0" onClick={() => setShowModal(true)}>
          <FilledAcademyIcon fill="#000" />
        </button>
        <button className="btn-action flex-grow-1" onClick={onNext}>
          {t('next')}
        </button>
      </div>

      {showModal && (
        <ExplanationBottomSheet
          content={explanation}
          layoutZIndex={3}
          handleClose={() => {
            setShowModal(false)
          }}
        />
      )}
    </div>
  )
}

export default QuizButtons
