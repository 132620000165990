import '../quiz/Quiz.css'

import { useTranslation } from 'react-i18next'

import Animation from '@/components/animation/Animation'

export function ErrorAlertScreen({ text, onClick, buttonTitle }) {
  const { t } = useTranslation()
  return (
    <div className="d-flex flex-column vh-100">
      <div className="d-flex flex-column justify-content-center vh-100 align-items-center px-3">
        <div className="d-flex flex-column px-3 rounded-dark-card w-100">
          <Animation className="s128" name="SkullAndBones" />
          <h5 className="mb-0 mt-3">{t('error')}</h5>
          <div className="fs-6 text-white-50 mt-2">{text}</div>
        </div>
      </div>
      <div className="container fixed-bottom rank-footer start-0 w-100 px-3 pt-0">
        <button className="btn-action" onClick={onClick}>
          {buttonTitle ?? t('retry')}
        </button>
      </div>
    </div>
  )
}
