import { dragSlice } from '@/components/drag-slider/DragSlice.js'
import { planetSlice } from '@/features/planet/planetSlice.js'
import { tasksSlice } from '@/features/tasks/tasksSlice.js'
import { usersSlice } from '@/features/user/usersSlice'
import { combineSlices, configureStore } from '@reduxjs/toolkit'

const makeStore = () => {
  const reducers = combineSlices(planetSlice, usersSlice, tasksSlice, dragSlice)

  return configureStore({
    reducer: reducers
  })
}

export const store = makeStore()
