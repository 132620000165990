const parseColor = color => {
  if (typeof color === 'string') {
    if (color.startsWith('rgb')) {
      return color.match(/\d+/g).map(Number)
    }
    if (color.startsWith('#')) {
      return [parseInt(color.slice(1, 3), 16), parseInt(color.slice(3, 5), 16), parseInt(color.slice(5, 7), 16)]
    }
  }
  throw new Error('Unsupported color type')
}

export const interpolate = (colors, progress) => {
  if (!Array.isArray(colors)) {
    return colors
  }

  const parsedColors = colors
    .map(stop => ({ color: parseColor(stop.color), position: stop.position }))
    .sort((a, b) => a.position - b.position)

  for (let i = 1; i < parsedColors.length; i++) {
    if (progress <= parsedColors[i].position) {
      const start = parsedColors[i - 1]
      const end = parsedColors[i]
      const t = (progress - start.position) / (end.position - start.position)
      const interpolatedColor = start.color.map((channel, index) =>
        Math.round(channel + (end.color[index] - channel) * t)
      )
      return `rgb(${interpolatedColor.join(',')})`
    }
  }
  return `rgb(${parsedColors[parsedColors.length - 1].color.join(',')})`
}
