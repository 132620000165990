import { useEffect, useState } from 'react'

const increaseTime = 500

function StubTaskTimer({ startFrom, interval, onComplete }) {
  const [, setTimeLeft] = useState(interval + increaseTime)

  useEffect(() => {
    if (!startFrom) return
    const timer = setInterval(() => {
      const currentTime = Date.now()
      const newTimeLeft = interval + increaseTime - (currentTime - startFrom)

      if (newTimeLeft <= 0) {
        clearInterval(timer)
        onComplete()
      } else {
        setTimeLeft(newTimeLeft)
      }
    }, 1000)

    return () => clearInterval(timer)
  }, [startFrom, interval, onComplete])

  return null
}

export default StubTaskTimer
