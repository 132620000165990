import { AutoTextSize } from 'auto-text-size'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Animation from '@/components/animation/Animation.jsx'
import QuizAnswers from '@/features/planet/quiz/QuizAnswers.jsx'
import QuizButtons from '@/features/planet/quiz/QuizButtons.jsx'
import QuizQuestionBoxMessage from '@/features/planet/quiz/QuizQuestionBoxMessage.jsx'
import { QuizState } from '@/features/planet/quiz/QuizState.jsx'
import QuizTimer from '@/features/planet/quiz/QuizTimer.jsx'

const QuizQuestion = ({
  state,
  question,
  questionIndex,
  questionCount,
  answers,
  correctAnswers,
  explanation,
  onSelectAnswers,
  onTimeout,
  onNext
}) => {
  const { t } = useTranslation()

  const showSuccess =
    state === QuizState.SHOWING_ANSWER &&
    answers &&
    answers.length &&
    JSON.stringify(answers.sort()) === JSON.stringify(correctAnswers.sort())

  let boxTopContent = useMemo(() => {
    if (state === QuizState.SHOWING_QUESTION) {
      return (
        <QuizTimer questionIndex={questionIndex} questionCount={questionCount} started={true} onFinish={onTimeout} />
      )
    } else if (state === QuizState.SUBMITTING_ANSWER) {
      if (answers && answers.length) {
        return <QuizTimer questionIndex={questionIndex} questionCount={questionCount} started={false} />
      } else {
        return <QuizQuestionBoxMessage animation="Crossmark" text={t('timeOut')} />
      }
    } else if (state === QuizState.SHOWING_ANSWER) {
      if (answers && answers.length) {
        if (JSON.stringify(answers.sort()) === JSON.stringify(correctAnswers.sort())) {
          return <QuizQuestionBoxMessage animation="Checkmark" text={t('correctAnswer')} />
        } else {
          return <QuizQuestionBoxMessage animation="Crossmark" text={t('wrongAnswer')} />
        }
      } else {
        return <QuizQuestionBoxMessage animation="Crossmark" text={t('timeOut')} />
      }
    } else {
      return <div />
    }
  }, [state])

  return (
    <div>
      <div className="px-3" style={{ paddingBottom: state === QuizState.SHOWING_ANSWER ? 105 : 24 }}>
        <div className="bg-black-40 rounded-32px" style={{ height: 164 }}>
          <div className="d-flex flex-column p-3">
            {boxTopContent}

            <div className="base-font m-2 align-items-center justify-content-center text-center" style={{ height: 89 }}>
              <AutoTextSize mode="box">{question.text}</AutoTextSize>
            </div>
          </div>
        </div>

        <div className=" mt-3">
          <QuizAnswers
            key={question?.id}
            questionId={question?.id}
            options={question?.options}
            answers={answers}
            correctAnswers={correctAnswers}
            onSelect={index => {
              onSelectAnswers([index])
            }}
          />
        </div>
      </div>

      {state === QuizState.SHOWING_ANSWER && <QuizButtons explanation={explanation} onNext={onNext} />}

      {showSuccess ? (
        <Animation
          name="QuizConfetti"
          loop={false}
          className="full-height-anim position-absolute w-100 h-100 start-0 bottom-0"
        />
      ) : null}
    </div>
  )
}

export default QuizQuestion
