import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { getUser } from '@/api/api.js'
import percentIcon from '@/assets/percent.svg'
import quizIcon from '@/assets/question.svg'
import tasksIcon from '@/assets/unordered-active.svg'
import referralsIcon from '@/assets/user plus-active.svg'
import uwtIcon from '@/assets/uwt.svg'
import Loader from '@/components/loader/Loader'
import { ErrorAlertScreen } from '@/features/planet/quiz/ErrorAlertScreen'
import { ProfileItem } from '@/features/user/ProfileItem'
import { selectUser } from '@/features/user/usersSlice'

export function Profile() {
  const { id } = useParams()
  const navigate = useNavigate()
  const myUser = useSelector(selectUser)

  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)

  const { t } = useTranslation()

  useEffect(() => {
    if (myUser?.id === id) {
      setUser(myUser)
      setLoading(false)
      return
    }

    getUser(id)
      .then(user => {
        setUser(user)
      })
      .catch(err => {
        console.error('Failed to copy: ', err)
      })
      .finally(() => setLoading(false))
  }, [id])

  const { referralsCount, taskPoints, friendsPoints } = useMemo(() => {
    if (!user) {
      return { referralsCount: 0, taskPoints: 0, friendsPoints: 0 }
    }

    let referralsCount = 0
    let pointsForFriendsWithTaskRewards = user.earned_points - user.earned_points_for_referral
    let taskPoints = user.earned_points_for_referral

    if (pointsForFriendsWithTaskRewards >= 10000 + 3600 + 1600 + 25 * 240) {
      referralsCount = (pointsForFriendsWithTaskRewards - 10000 - 3600 - 1600) / 240
      taskPoints += 10000 + 3600 + 1600
    } else if (pointsForFriendsWithTaskRewards >= 3600 + 1600 + 10 * 240) {
      referralsCount = (pointsForFriendsWithTaskRewards - 3600 - 1600) / 240
      taskPoints += 3600 + 1600
    } else if (pointsForFriendsWithTaskRewards >= 1600 + 5 * 240) {
      referralsCount = (pointsForFriendsWithTaskRewards - 1600) / 240
      taskPoints += 1600
    } else {
      referralsCount = pointsForFriendsWithTaskRewards / 240
    }

    const friendsPoints = referralsCount * 240

    return { referralsCount, taskPoints, friendsPoints }
  }, [user])

  if (loading) {
    return <Loader />
  }

  if (!user) {
    return <ErrorAlertScreen text={t('somethingWrong')} onClick={() => navigate(-1)} buttonTitle={t('goBack')} />
  }

  return (
    <div className="stars-container pt-3 px-3 text-white h-100">
      <div className="d-flex flex-fill flex-column align-items-center mb-4">
        <div className="mb-3">
          {user.avatar ? (
            <img src={user.avatar} className="rounded-circle s100" alt="user" />
          ) : (
            <div className="d-flex flex-row s100 justify-content-center align-items-center mb-0 rounded-circle bg-warning text-black fs-9">
              {user.name.charAt(0).toUpperCase()}
            </div>
          )}
        </div>
        <div className="fs-1 fw-medium">{user.name}</div>
      </div>

      <div className="bg-cl-dark1 rounded-4">
        <ProfileItem icon={uwtIcon} title={t('totalEarned')} value={user.points.toLocaleString()} />
        {id === myUser?.id && (
          <div className="px-3 pt-2 pb-3">
            <button className="btn-action" disabled>
              <div className="d-flex flex-row gap-1 justify-content-center align-items-center text-center">
                <span className="fs-5 fw-semibold">{t('claim')}</span>
              </div>
            </button>
          </div>
        )}
      </div>

      <div className="bg-cl-dark1 rounded-4 mt-3">
        <ProfileItem icon={quizIcon} title={t('quizRewards')} value={user.quiz_points.toLocaleString()} />
        {/*<div className="border-bottom" />*/}
        {/*<AnswerCountItem color="text-success" title={t('correctAnswers')} value={user.quiz_points.toLocaleString()} />*/}
        {/*<div className="border-bottom" />*/}
        {/*<AnswerCountItem color="text-danger" title={t('wrongAnswers')} value={user.quiz_points.toLocaleString()} />*/}
      </div>
      <div className="bg-cl-dark1 rounded-4 mt-3">
        <ProfileItem icon={tasksIcon} title={t('earnedFromTasks')} value={taskPoints.toLocaleString()} />
      </div>
      <div className="bg-cl-dark1 rounded-4 mt-3">
        <ProfileItem
          icon={referralsIcon}
          title={`${t('frens')} (${referralsCount.toLocaleString()})`}
          subtitle={t('frensInfo')}
          value={friendsPoints.toLocaleString()}
        />
      </div>
      <div className="bg-cl-dark1 rounded-4 mt-3">
        <ProfileItem
          icon={percentIcon}
          title={t('referrals')}
          subtitle={t('referralsInfo')}
          value={user.referral_points.toLocaleString()}
        />
      </div>
    </div>
  )
}
